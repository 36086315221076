import ButtonStyle from "../../../ui/button";
import ListType from "../../../ui/listType";
import { Card, LinkText, TitleText } from "../../../ui/styles";

const businessChoiceDetails = [
  "Friendly dashboard analytics",
  "Extensive customer-level information",
  "Reports on daily, monthly, yearly transactions",
  "12 - 24 hours settlement",
];

const RightDiv = () => {
  return (
    <Card flex flexdirection="column" gap="20px" width="430px" smwidth="100%">
      <TitleText
        size="32px"
        lineheight="43.52px"
        weight="600"
        smsize="24px"
        smlineheight="34px"
      >
        Influence all your key business choices
      </TitleText>
      {businessChoiceDetails.map((detail, index) => (
        <ListType text={detail} key={index} />
      ))}
      <Card margin="20px 0 0">
        <LinkText to="/coming-soon/">
          <ButtonStyle text="Get Started" />
        </LinkText>
        {/* <a href="https://app.fountainpay.ng/" target="_blank">
          <ButtonStyle text="Get Started" />
        </a> */}
      </Card>
    </Card>
  );
};

export default RightDiv;
