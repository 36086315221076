import { Button, deepBlue, primaryRed, white } from "../styles";

interface IButtonProps {
  invertStyle?: boolean;
  text?: string;
  width?: string;
  onClick?: Function;
}

const ButtonStyle = (props: IButtonProps) => {
  const { invertStyle, onClick, text, width } = props;
  return (
    <Button
      padding="13px 35px"
      size="18px"
      mdsize="14px"
      lineheight="27px"
      heavy
      color={white}
      bg={invertStyle ? deepBlue : primaryRed}
      radius="10px"
      width={width}
      onClick={() => onClick}
    >
      {text}
    </Button>
  );
};

export default ButtonStyle;
