import { Card, Image, InnerSection, MainSection } from "../../../ui/styles";
import Title from "../../../ui/title";
import RightDiv from "./RightDiv";

const ProcessCheckout = () => {
  return (
    <MainSection width="100%" mtop="100px" smmargin="70px 0 0" data-aos="fade-up">
      <InnerSection>
        <Card flex flexdirection="column" gap="50px" smgap="20px">
          <Title title="Payment processing" span="checkout" width="533px" />
          <Card
            flex
            gap="130px"
            mdflexdirection="column"
            mdgap="40px"
            smgap="40px"
          >
            <Image
              src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680684708/Fountainpay-V2_Assets/Frame_4316_vc5boc.svg"
              alt="fountainpay"
              smwidth="100%"
              flexratio="1"
              data-aos="fade-up"
            />
            <RightDiv />
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default ProcessCheckout;
