import { Card, MainSection } from "../../../../ui/styles";
import LeftDiv from "./LeftDiv";
import RightDiv from "./RightDiv";

export interface ITestimonialProps {
  changingIndex: any;
  setChangingIndex?: any;
}

const TestimonialBody = (props: ITestimonialProps) => {
  const { changingIndex } = props;
  return (
    <MainSection
      display="flex"
      gap="32px"
      smflexdirection="column-reverse"
      data-aos="flip-down"
      data-aos-easing="linear"
      data-aos-duration="1000"
      style={{gap:"32px"}}
    >
      <LeftDiv changingIndex={changingIndex} />
      <RightDiv changingIndex={changingIndex} />
    </MainSection>
  );
};

export default TestimonialBody;
