import { useState } from "react";
import { coreProductDetails } from "../../../../data";
import {
  Card,
  Image,
  InnerSection,
  LinkText,
  MainSection,
  primaryRed,
  Text,
} from "../../../ui/styles";
import Title from "../../../ui/title";
import CoreProductBody from "./coreProductBody/CoreProductBody";

const CoreProduct = () => {
  const [clickedIndex, setClickedIndex] = useState(0);

  const handleClicked = (index: any) => {
    if (clickedIndex === index) {
      setClickedIndex(0);
    }
    setClickedIndex(index);
  };
  return (
    <MainSection width="100%" mtop="100px" data-aos="fade-up">
      <InnerSection>
        <Card flex flexdirection="column" gap="50px">
          <Card
            flex
            justifycontent="space-between"
            alignitems="end"
            mdalignitems="center"
          >
            <Title title="Our Core" span="Products" width="256px" />
            {coreProductDetails.map(
              (detail, index) =>
                clickedIndex === index && (
                  <LinkText
                    display="flex"
                    gap="10px"
                    alignitems="center"
                    mdalignitems="center"
                    to={detail.path}
                    smdisplay="none"
                    key={index}
                  >
                    <Text
                      size="16px"
                      lineheight="27.2px"
                      color={primaryRed}
                      heavy
                      nowrap
                    >
                      Learn more
                    </Text>
                    <Image
                      src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680520780/Fountainpay-V2_Assets/Vector_fkjqff.png"
                      mdheight="10px"
                      smheight="unset"
                      mdmargin="0 0 0 5px"
                    />
                  </LinkText>
                )
            )}
          </Card>
          <CoreProductBody
            clickedIndex={clickedIndex}
            handleClicked={handleClicked}
          />
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default CoreProduct;
