import { coreProductDetails } from "../../../../../data";
import { Card, Image } from "../../../../ui/styles";

const RightDIv = (props: { clickedIndex: any }) => {
  const { clickedIndex } = props;
  return (
    <Card
      height="565px"
      mdheight="unset"
      shadow="0px 4px 50px rgba(0, 0, 0, 0.05)"
    >
      {coreProductDetails.map(
        (detail, index) =>
          clickedIndex === index && <Image src={detail.image} key={index} mdwidth="100%" smwidth="100%" />
      )}
    </Card>
  );
};

export default RightDIv;
