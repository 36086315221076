import { Card, MainSection } from "../styles";

const BackgroundGradient = () => {
  return (
    <MainSection
      smdisplay="none"
      width="100%"
      maxwidth="1440px"
      overflow="hidden"
      position="relative"
      top="0"
      left="0"
      index="-1"
    >
      <Card
        width="970px"
        height="970px"
        radius="50%"
        position="absolute"
        bg="rgba(234, 19, 19, 0.05)"
        filter="blur(400px)"
        top="1375px"
        left="732px"
      />
      <Card
        width="1729px"
        height="1729px"
        radius="50%"
        position="absolute"
        bg="rgba(234, 19, 19, 0.05)"
        filter="blur(400px)"
        top="2020px"
        left="-805px"
      />
      <Card
        width="909px"
        height="909px"
        radius="50%"
        position="absolute"
        bg="rgba(234, 19, 19, 0.05)"
        filter="blur(400px)"
        top="3746px"
        left="793px"
      />
    </MainSection>
  );
};

export default BackgroundGradient;
