import { socialMediaDetails } from "../../../../../data";
import { black, Card, Image, Text } from "../../../styles";

const AboutFountainpay = () => {
  return (
    <Card flex flexdirection="column" gap="14px" width="285px">
      <Image
        src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680257310/Fountainpay-V2_Assets/FountainPay_Logo_wg2i2c_1_xftv3z.png"
        alt="fountainpay-logo"
        width="192px"
        smwidth="120px"
      />
      <Text size="16px" lineheight="22px" color={black} width="285px" smwidth="100%">
        FountainPay is your all-in-one daily business management, growth, and
        profit solution.
      </Text>
      <Card flex gap="17px" alignitems="center" margin="30px 0 0">
        {socialMediaDetails.map((social, index) => (
          <a href={social.handle} target="_blank" key={index}>
            <Image src={social.icon} alt={social.name} />
          </a>
        ))}
      </Card>
      <Card flex gap="23px" alignitems="center" margin="30px 0 0" smmargin="10px 0 0">
        <Image src="https://res.cloudinary.com/dyozdezpg/image/upload/v1681217753/Fountainpay-V2_Assets/logo-pci_dss-300x300_1_khwfmn.svg" />
        <Image src="https://res.cloudinary.com/dyozdezpg/image/upload/v1681217753/Fountainpay-V2_Assets/Group_v18ls7.png" />
      </Card>
    </Card>
  );
};

export default AboutFountainpay;
