import Layout from '../components/ui/layout'
import GetStarted from '../components/ui/layout/footer/GetStarted'
import OtherPagesGradient from '../components/ui/otherPagesGradient'
import { Card } from '../components/ui/styles'
import AboutHeroSection from '../components/websitePageSections/about/aboutHeroSection'
import CoreValue from '../components/websitePageSections/about/coreValue'
import Mission from '../components/websitePageSections/about/mission'
import WhatWeStandFor from '../components/websitePageSections/about/whatWeStandFor'

const AboutPage = () => {
  return (
    <Layout>
        <Card overflow="hidden" smmargin="-40px 0 0">
          <OtherPagesGradient />
          <AboutHeroSection />
          <WhatWeStandFor />
          <Mission />
          <CoreValue />
          <GetStarted />
        </Card>
    </Layout>
  )
}

export default AboutPage