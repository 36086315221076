import { Card, Text, TitleText, white } from "../../../ui/styles";

interface IWhoProps {
  navController?: any;
  setNavController?: any;
  fountainpayGet?: any;
  youGet?: any;
}

const WhatYouGet = (props: IWhoProps) => {
  const { navController, setNavController, fountainpayGet, youGet } = props;
  return (
    <Card
      flex
      justifycontent="space-between"
      width="680px"
      mdwidth="100%"
      alignitems="center"
    >
      <Card flex flexdirection="column" gap="5px">
        <Text size="14px" lineheight="21px" color={white} weight="300">
          You {navController === "business" ? "get" : "pay"}
        </Text>
        <TitleText size="18px" lineheight="24px" color={white} weight="500">
          NGN {youGet.toFixed(2)}
        </TitleText>
      </Card>
      <Card flex flexdirection="column" gap="5px">
        <Text size="14px" lineheight="21px" color={white} weight="300">
          Fountainpay get
        </Text>
        <TitleText size="18px" lineheight="24px" color={white} weight="500">
          NGN {fountainpayGet.toFixed(2)}
        </TitleText>
      </Card>
    </Card>
  );
};

export default WhatYouGet;
