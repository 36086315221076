import ButtonStyle from "../../../ui/button";
import { Card, InnerSection, LinkText, MainSection } from "../../../ui/styles";
import Title from "../../../ui/title";
import Features from "./features/Features";

const BankingFeatures = () => {
  return (
    <MainSection width="100%" mtop="100px" smmargin="70px 0 0">
      <InnerSection>
        <Card
          flex
          flexdirection="column"
          gap="50px"
          smgap="20px"
          alignitems="center"
        >
          <Title title="Agency Banking" span="Features" center />
          <Features />
          <LinkText to="/coming-soon/">
            <ButtonStyle text="Get Started" />
          </LinkText>
          {/* <a href="https://app.fountainpay.ng/" target="_blank">
            <ButtonStyle text="Get Started" />
          </a> */}
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default BankingFeatures;
