import { Card, Image, InnerSection, MainSection } from "../../../ui/styles";
import Title from "../../../ui/title";
import LeftDiv from "./LeftDiv";

const SignUpWithFountainpay = () => {
  return (
    <MainSection
      width="100%"
      mtop="100px"
      smmargin="70px 0 0"
      data-aos="fade-up"
    >
      <InnerSection>
        <Card flex flexdirection="column" gap="50px" smgap="20px">
          <Title title="Sign up on" span="FountainPay" center />
          <Card
            flex
            justifycontent="space-between"
            alignitems="center"
            mdalignitems="unset"
            smalignitems="center"
            mdflexdirection="column-reverse"
            mdgap="40px"
            smgap="40px"
          >
            <LeftDiv />
            <Card>
              <Image
                src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680684703/Fountainpay-V2_Assets/Frame_4265_aoclvd.svg"
                alt="fountainpay"
                smwidth="100%"
                data-aos="fade-up"
              />
            </Card>
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default SignUpWithFountainpay;
