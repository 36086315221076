import { useEffect, useState } from "react";
import { Card, gray, Image } from "../../../ui/styles";

const heroImage = [
  "https://res.cloudinary.com/dyozdezpg/image/upload/v1680516009/Fountainpay-V2_Assets/Rectangle_4188_mmpddx.png",
  "https://res.cloudinary.com/dyozdezpg/image/upload/v1680516009/Fountainpay-V2_Assets/Rectangle_4187_hvyu7h.png",
  "https://res.cloudinary.com/dyozdezpg/image/upload/v1680516009/Fountainpay-V2_Assets/Rectangle_4186_tsv8wq.png",
];

const HeroImage = () => {
  const [changingIndex, setChangingIndex] = useState(0);

  useEffect(() => {
    const newIndex =
      changingIndex > heroImage.length - 2 ? 0 : changingIndex + 1;

    const timer = setTimeout(() => {
      setChangingIndex(newIndex);
    }, 2500);

    return () => clearTimeout(timer);
  }, [changingIndex]);

  return (
    <Card
      height="382px"
      mdheight="unset"
      smheight="200px"
      width="100%"
      bg={gray}
      radius="20px"
      smradius="8px"
      margin="45px 0 0"
      smmargin="20px 0 0"
      overflow="hidden"
      position="relative"
    >
      {heroImage.map(
        (image, index) =>
          changingIndex === index && (
            <Image
              data-aos="fade-in"
              data-aos-easing="linear"
              data-aos-duration="1000"
              key={index}
              src={image}
              width="100%"
              smheight="100%"
              smwidth="unset"
              position="absolute"
            />
          )
      )}
    </Card>
  );
};

export default HeroImage;
