import Layout from "../components/ui/layout";
import GetStarted from "../components/ui/layout/footer/GetStarted";
import { Card } from "../components/ui/styles";

const PaymentSecurityPage = () => {
  return (
    <Layout>
      <Card overflow="hidden" smmargin="-40px 0 0">
        <GetStarted />
      </Card>
    </Layout>
  );
};

export default PaymentSecurityPage;
