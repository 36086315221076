import ButtonStyle from "../../../ui/button";
import MainText from "../../../ui/mainText";
import PageIntro from "../../../ui/pageIntro";
import {
  black,
  Card,
  InnerSection,
  LinkText,
  MainSection,
  primaryRed,
  TitleText,
} from "../../../ui/styles";
import HeroImage from "./HeroImage";

const HomeHeroSection = () => {
  return (
    <>
      <MainSection
        width="100%"
        padding="100px 0 0"
        mdpadding="70px 0 0"
        data-aos="fade-up"
      >
        <InnerSection>
          <Card
            flex
            gap="25px"
            flexdirection="column"
            alignitems="center"
            mdflexdirection="column"
          >
            <PageIntro
              text="Welcome to Fountainpay"
              icon="https://res.cloudinary.com/dyozdezpg/image/upload/v1680514209/Fountainpay-V2_Assets/emoji__waving_hand__dudsop.png"
            />
            <TitleText
              size="54px"
              lineheight="73.44px"
              width="900px"
              mdwidth="100%"
              mdsize="50px"
              mdlineheight="65px"
              smsize="30px"
              smlineheight="40px"
              weight="600"
              color={black}
              center
            >
              The best seamless and secure payment option for{" "}
              <span style={{ color: `${primaryRed}` }}>all business</span>
            </TitleText>
            <Card margin="-13px 0 0">
              <MainText
                text="We provide thousands of business with all the tools it needs to
                manage payments and transact daily on a single and secured
                platform."
                width="780px"
                center
              />
            </Card>
            <LinkText to="/coming-soon/">
              <ButtonStyle text="Get Started" />
            </LinkText>
            {/* <a href="https://app.fountainpay.ng/" target="_blank">
              <ButtonStyle text="Get Started" />
            </a> */}

            <HeroImage />
          </Card>
        </InnerSection>
      </MainSection>
    </>
  );
};

export default HomeHeroSection;
