import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

type Props={
    show?: boolean;
}
const Overlay = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  ${props=>props.show? css `
    display: flex;
    justify-content: center;
    align-items: center;
  `: css`
    display: none;
  `}
`;

const Modal = styled.div`
  background: #002b5c;
  color: white;
  padding: 20px;
  border-radius: 5px;
  /* max-width: 500px; */
  text-align: left;
  position: fixed;
  bottom: 0px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 10px;
  padding: 0px 10%;
`;

const Description = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0px 10%;
  &>div{
    width: 25%;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  padding: 0px 10%;
`;

const Button = styled.button<{ variant: string }>`
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  background-color: ${({ variant }) =>
    variant === 'accept' ? '#4caf50' : variant === 'decline' ? '#f44336' : '#2196f3'};
  color: white;

  &:hover {
    opacity: 0.8;
  }
`;

const Cookie: React.FC = () => {
    const[show, setShow]=useState(true)
    const accept=()=>{
        localStorage.setItem("cookie", JSON.stringify({accept: false}));
        setShow(false)
    }
    const close=()=>{
        localStorage.setItem("cookie", JSON.stringify({accept: true}));
        setShow(false)
    }
    return (
        <Overlay show={show}>
            <Modal>
                <Title>Its your choice</Title>
                <Description>
                  <div>
                    When we make the FountainPay available to you online, we and our partners may use cookies and similar technologies to help us to do this. These are necessary to help our platforms work properly and cant be switched off, and none is optional because they support the FountainPay and our experience in other ways. To do this, we work with cross section of partners.
                  </div>
                  <div>
                    Cookies and other similar technologies may be used to access personal data, including page visits and your IP address. We use this information about you, your devices and your online interactions with us to provide, analyse and improve our services. Because of our role in the financial sector, We will use your data to personalise content and suggest possible services.
                  </div>
                  <div>
                    <strong>We use cookies and similar technologies for the following purpose:</strong>
                    <ul>
                      <li>Store and/or access information on a device</li>  
                      <li>Track locations where your account is been accessed from so that we can respond as soon as possible when we notice that something irregular</li>
                      <li>Personalise content measurement, audience research and service development</li>
                    </ul>
                  </div>
                  <div>
                  Learn more in our <Link to="/privacy">privacy policy</Link> and <Link to="/privacy">cookie policy</Link>, and manage the choices available to you at the time at the bottom of the page
                  </div>
                </Description>
                <div style={{paddingLeft: '10%', paddingRight: '10%', marginBottom: '10px'}}>
                  <hr />
                Are you happy to accept cookies?<br />
                To accept your cookie choices now, or to opt out where our partners rely on legitimate interests to use your information, click on manage cookies.
                The FountainPay consent message
                </div>
                <ButtonGroup>
                  <Button variant="accept" onClick={accept}>Yes, I accept</Button>
                  <Button variant="decline" onClick={close}>No, thank you</Button>
                </ButtonGroup>
            </Modal>
        </Overlay>
    );
};

export default Cookie;