import { black, Card, gray, Image, Text, TitleText } from "../styles";

interface IProcessProps {
  bg?: string;
  border?: string;
  width?: string;
  height?: string;
  title?: string;
  text?: string;
  absolute?: boolean;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  icon?: string;
}

const ProcessCard = (props: IProcessProps) => {
  const {
    bg,
    border,
    text,
    title,
    width,
    absolute,
    top,
    left,
    right,
    bottom,
    height,
    icon,
  } = props;
  return (
    <Card
      position={absolute ? "absolute" : "unset"}
      radius="10px"
      shadow="0px 4px 50px rgba(0, 0, 0, 0.05);"
      bg={bg}
      border={`1px solid ${border}`}
      padding="30px 25px"
      flex
      flexdirection="column"
      gap="20px"
      smgap="10px"
      width={width}
      mdwidth="unset"
      height={height}
      smwidth="100%"
      top={top}
      bottom={bottom}
      right={right}
      left={left}
      data-aos="fade-up"
      mdposition="unset"
    >
      {icon && <Image src={icon} alt={title} width="32px" />}
      <TitleText
        size="24px"
        weight="600"
        lineheight="136%"
        smsize="18px"
        smlineheight="24px"
        color={black}
      >
        {title}
      </TitleText>
      <Text
        size="16px"
        weight="400"
        lineheight="27.2px"
        smsize="14px"
        smlineheight="18px"
        color={gray}
      >
        {text}
      </Text>
    </Card>
  );
};

export default ProcessCard;
