import BackgroundGradient from "../components/ui/backgroundGradient";
import HeroAnimation from "../components/ui/heroAnimation";
import Layout from "../components/ui/layout";
import GetStarted from "../components/ui/layout/footer/GetStarted";
import { Card } from "../components/ui/styles";
import Contact from "../components/websitePageSections/home/contact";
import CoreProduct from "../components/websitePageSections/home/coreProduct";
import HomeHeroSection from "../components/websitePageSections/home/homeHeroSection";
import PaymentCollection from "../components/websitePageSections/home/paymentCollection";
import Testimonials from "../components/websitePageSections/home/testimonials";

const HomePage = () => {
  return (
    <Layout>
      <Card overflow="hidden" smmargin="-40px 0 0">
        <HeroAnimation />
        <BackgroundGradient />
        <HomeHeroSection />
        <PaymentCollection />
        <CoreProduct />
        <Testimonials />
        <Contact />
        <GetStarted />
      </Card>
    </Layout>
  );
};

export default HomePage;
