import ButtonStyle from "../../../ui/button";
import ListType from "../../../ui/listType";
import { Card, LinkText, TitleText } from "../../../ui/styles";

const agencyBankingSteps = [
  "visit app.fountainpay.ng",
  "Complete your KYC",
  "Request and purchase your POS",
  "Start receiving  and making payment on your POS",
  "Get access to your dashboard",
];

const LeftDiv = () => {
  return (
    <Card flex flexdirection="column" gap="20px" width="430px" smwidth="100%">
      <TitleText
        size="32px"
        lineheight="43.52px"
        weight="600"
        smsize="24px"
        smlineheight="34px"
      >
        Create an account in four simple steps
      </TitleText>
      {agencyBankingSteps.map((detail, index) => (
        <ListType text={detail} key={index} />
      ))}
      <Card margin="20px 0 0">
        <LinkText to="/coming-soon/">
          <ButtonStyle text="Get Started" />
        </LinkText>
        {/* <a href="https://app.fountainpay.ng/" target="_blank">
          <ButtonStyle text="Get Started" />
        </a> */}
      </Card>
    </Card>
  );
};

export default LeftDiv;
