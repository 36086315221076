import React from "react";
import { Card, Image, InnerSection, MainSection } from "../../../ui/styles";
import Title from "../../../ui/title";
import LeftDiv from "./LeftDiv";

const AgencyBanking = () => {
  return (
    <MainSection width="100%" mtop="100px" smmargin="70px 0 0">
      <InnerSection>
        <Card flex flexdirection="column" gap="50px" smgap="20px" width="100%" alignitems="center">
          <Title
            title="How to sign up on"
            span="Agency Banking"
            width="663px"
            center
          />
          <Card
            flex
            mdflexdirection="column"
            justifycontent="space-between"
            alignitems="center"
            mdalignitems="unset"
            smalignitems="center"
            gap="50px"
            width="100%"
          >
            <Image
              src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680689176/Fountainpay-V2_Assets/Frame_4265_oz8nba.svg"
              alt="fountainpay"
              mdwidth="100%"
            />
            <LeftDiv />
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default AgencyBanking;
