import React from "react";
import {
  Card,
  InnerSection,
  MainSection,
  TitleText,
  black,
  primaryRed,
} from "../../ui/styles";
import PageIntro from "../../ui/pageIntro";
import ButtonStyle from "../../ui/button";
import MainText from "../../ui/mainText";

const ComingSoon = () => {
  return (
    <MainSection
      width="100%"
      padding="100px 0 100px"
      mdpadding="70px 0 70px"
      data-aos="fade-up"
    >
      <InnerSection>
        <Card
          flex
          gap="25px"
          flexdirection="column"
          alignitems="center"
          mdflexdirection="column"
          overflow="hidden"
        >
          <PageIntro text="Coming Soon" />
          <TitleText
            size="54px"
            lineheight="73.44px"
            width="900px"
            mdwidth="100%"
            mdsize="50px"
            mdlineheight="65px"
            smsize="30px"
            smlineheight="40px"
            weight="600"
            color={black}
            center
          >
            Thank you for your interest in{" "}
            <span style={{ color: `${primaryRed}` }}>partnering with us</span>
          </TitleText>
          <Card margin="-13px 0 0">
            <MainText
              text="Fountain Payment Systems and Solutions is currently onboarding its merchant privately"
              center
            />
          </Card>
          <a
            href="mailto:info@fountainpay.ng?subject=I will like to partner with Fountain Payment Systems and Solutions"
            target="_blank"
          >
            <ButtonStyle text="Contact us" />
          </a>

          {/* <HeroImage /> */}
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default ComingSoon;
