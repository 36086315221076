import ButtonStyle from "../../../ui/button";
import MainText from "../../../ui/mainText";
import PageIntro from "../../../ui/pageIntro";
import {
  black,
  Card,
  Image,
  InnerSection,
  LinkText,
  MainSection,
  primaryRed,
  TitleText,
} from "../../../ui/styles";

const GatewayHeroSection = () => {
  return (
    <MainSection
      width="100%"
      padding="100px 0 0"
      mdpadding="70px 0 0"
      data-aos="fade-up"
    >
      <InnerSection>
        <Card flex gap="25px" flexdirection="column" alignitems="center">
          <PageIntro text="Payment Processing" />
          <TitleText
            size="54px"
            lineheight="73.44px"
            width="900px"
            mdwidth="100%"
            mdsize="50px"
            mdlineheight="65px"
            smsize="30px"
            smlineheight="40px"
            weight="600"
            color={black}
            center
          >
            Send and accept payments in{" "}
            <span style={{ color: `${primaryRed}` }}>multiple ways</span>
          </TitleText>
          <Card margin="-13px 0 0">
            <MainText
              text="Quick and secure way to send and receive money. Receive and spend money anytime and anywhere."
              center
              width="500px"
            />
          </Card>
          <LinkText to="/coming-soon/">
            <ButtonStyle text="Get Started" />
          </LinkText>
          {/* <a href="https://app.fountainpay.ng/" target="_blank">
            <ButtonStyle text="Get Started" />
          </a> */}

          <Image
            src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680684702/Fountainpay-V2_Assets/Group_4261_w48dwh.svg"
            alt="fountainpay"
            mdwidth="100%"
          />
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default GatewayHeroSection;
