import React from "react";
import ListType from "../../../ui/listType";
import MainText from "../../../ui/mainText";
import {
  black,
  Card,
  primaryRed,
  TitleText,
  white,
} from "../../../ui/styles";

const pricingData = [
  {
    title: "For Local Transaction",
    titleText: "1.4% + NGN 50",
    features: [
      "N50 waived fee for transactions up to N3000",
      "Local transaction fees are capped at N2000 per transaction, which is the most you will ever pay.",
    ],
  },
  {
    title: "For International Transaction",
    titleText: "3.9% + NGN 50",
    features: [
      "Receive payments from all over the world",
      "By default, international cards are charged and paid for in your local currency.",
    ],
  },
];

const PricingCards = () => {
  return (
    <Card flex mdflexdirection="column" gap="32px" margin="85px 0 0" smmargin="40px 0 0">
      {pricingData.map((pricing, index) => (
        <Card
          key={index}
          radius="20px 20px 0 0"
          bg={primaryRed}
          padding="18px 5px 0"
          flex
          flexdirection="column"
          alignitems="center"
          gap="12px"
        >
          <MainText text={pricing.title} center color={white} />
          <Card
            flex
            flexdirection="column"
            gap="9px"
            padding="31px 34px"
            smpadding="15px"
            bg={white}
            radius="10px 10px 0 0"
            width="486px"
            mdwidth="100%"
          >
            <TitleText size="40px" lineheight="60px" smsize="32px" smlineheight="30px" color={black} heavy>
              {pricing.titleText}
            </TitleText>
            <hr
              style={{ background: "rgba(215, 215, 215, 1)", width: "100%" }}
            />
            <Card flex flexdirection="column" margin="26px 0 0" gap="20px" smmargin="0">
              {pricing.features.map((feature, index) => (
                <ListType key={index} text={feature} />
              ))}
            </Card>
          </Card>
        </Card>
      ))}
    </Card>
  );
};

export default PricingCards;
