import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { IFileUploadeProps } from "../../types/Types.d";


export const FileUpload=styled.div<IFileUploadeProps>`
    width: ${(props) => (props.width ? `${props.width}` : "100%")};
    height: ${(props) => (props.height ? `${props.height}` : "158px")};
    text-align: center;
    border: 3px dashed rgb(210, 227, 244);
    padding: 1.5rem;
    position: relative;
    cursor: pointer;

    &>img{
        width: 60px;
        height: 60px;
    }
    &>p {
        font-size: 0.87rem;
        margin-top: 10px;
        color: #bbcada;
    }
    &>input {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        cursor: pointer;
    }
`;

export const FileLists=styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    &>p{
        min-width: 10%;
        padding:5px;
        border: 0px;
        border-left: 3px solid green;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &>span{

        }
        &>img{

        }
    }
`