import { Card, Image, InnerSection, MainSection } from "../../../ui/styles";
import RightDiv from "./RightDiv";

const BusinessChoice = () => {
  return (
    <MainSection
      width="100%"
      padding="100px 0"
      bg="rgba(39, 59, 74, 0.02)"
      smpadding="70px 0"
      data-aos="fade-up"
    >
      <InnerSection>
        <Card
          flex
          mdflexdirection="column-reverse"
          justifycontent="space-between"
          alignitems="center"
          mdalignitems="unset"
          smalignitems="center"
          gap="50px"
        >
          <Image
            src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680684701/Fountainpay-V2_Assets/Group_4179_yc8elq.svg"
            alt="fountainpay"
            mdwidth="100%"
            data-aos="fade-up"
          />
          <RightDiv />
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default BusinessChoice;
