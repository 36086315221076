import Layout from "../components/ui/layout";
import GetStarted from "../components/ui/layout/footer/GetStarted";
import OtherPagesGradient from "../components/ui/otherPagesGradient";
import { Card } from "../components/ui/styles";
import DoTheMath from "../components/websitePageSections/pricing/doTheMath";
import PricingHeroSection from "../components/websitePageSections/pricing/pricingHeroSection";
import Settlement from "../components/websitePageSections/pricing/settlement";

const PricingPage = () => {
  return (
    <Layout>
      <Card overflow="hidden" smmargin="-40px 0 0">
        <OtherPagesGradient />
        <PricingHeroSection />
        <Settlement />
        <DoTheMath />
        <GetStarted />
      </Card>
    </Layout>
  );
};

export default PricingPage;
