import React from "react";
import {
  black,
  Card,
  InnerSection,
  InputLabel,
  MainSection,
  primaryRed,
  Text,
  TextField,
  TitleText,
  white,
} from "../../../ui/styles";

const CareerForm = () => {
  return (
    <MainSection width="100%" mtop="100px">
      <InnerSection>
        <Card
          padding="57px 112px"
          btop={`4px solid ${primaryRed}`}
          bg={white}
          shadow="0px 4px 50px rgba(0, 0, 0, 0.05)"
        >
          <Card flex flexdirection="column" gap="10px" color={black}>
            <TitleText size="32px" lineheight="43px">
              Frontend Developer
            </TitleText>
            <Card flex alignitems="center" gap="10px">
              <Text size="20px" lineheight="27px">
                Mid - Level Frontend Developer
              </Text>
              <Card width="8px" height="8px" bg={primaryRed} radius="50%" />
              <Text size="20px" lineheight="27px">
                Akure
              </Text>
            </Card>
          </Card>
          <Card flex flexdirection="column" gap="10px">
            <InputLabel htmlFor="name">Name</InputLabel>
          <TextField radius="5px" placeholder="Enter name" name="name" />
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default CareerForm;
