import { useEffect, useState } from "react";
import {
  Card,
  deepBlue,
  Image,
  InnerSection,
  MainSection,
  TextField,
  TitleText,
  white,
} from "../../../ui/styles";
import Title from "../../../ui/title";
import WhatYouGet from "./WhatYouGet";
import WhoBearsCharge from "./WhoBearsCharge";

const DoTheMath = () => {
  const [price, setPrice] = useState("2000");
  const [rate, setRate] = useState(false);
  const [youGet, setYouGet] = useState(0);
  const [navController, setNavController] = useState("customer");
  const [fountainpayGet, setFountainpayGet] = useState(0);

  const handleMath = () => {
    if (parseInt(price) < 3000) {
      setRate(false);
    } else {
      setRate(true);
    }
  };

  const handleYouGet = () => {
    if (parseInt(price) < 3000) {
      if (navController === "business") {
        const value = parseInt(price) - parseInt(price) * 0.014;
        setYouGet(value);
      } else if (navController === "customer") {
        const adValue = parseInt(price) * 0.014;
        const value = parseInt(price) + adValue;
        setYouGet(value);
      }
    } else {
      if (navController === "business") {
        const adValue = parseInt(price) * 0.014;
        const addValue = adValue + 50;
        const value = parseInt(price) - addValue;
        const interest = parseInt(price) * 0.014 + 50;
        setYouGet(interest > 2050 ? parseInt(price) - 2050 : value);
      } else {
        const adValue = parseInt(price) * 0.014;
        const addValue = adValue + 50;
        const value = parseInt(price) + addValue;
        const interest = parseInt(price) * 0.014 + 50;
        setYouGet(interest > 2050 ? parseInt(price) + 2050 : value);
      }
    }
  };

  const handleFountainpayGet = () => {
    if (parseInt(price) < 3000) {
      const value = parseInt(price) * 0.014;
      setFountainpayGet(value);
    } else {
      const adValue = parseInt(price) * 0.014;
      const value = adValue + 50;
      setFountainpayGet(value > 2050 ? 2050 : value);
    }
  };

  useEffect(() => {
    handleMath();
    handleYouGet();
    handleFountainpayGet();
  }, [price, navController]);

  return (
    <MainSection width="100%" mtop="100px" smmargin="70px 0 0">
      <InnerSection>
        <Card
          padding="50px"
          smpadding="20px"
          bg={deepBlue}
          radius="10px"
          position="relative"
          flex
          flexdirection="column"
          gap="25px"
          height="395px"
          smheight="unset"
          index="0"
          overflow="hidden"
        >
          <Card
            radius="50%"
            width="240px"
            height="240px"
            border="30px solid rgba(242, 153, 74, 1)"
            position="absolute"
            top="-97px"
            right="-120px"
            mddisplay="none"
            index="0"
          />
          <Card
            position="absolute"
            top="0"
            left="0"
            width="100%"
            mddisplay="none"
            index="-1"
          >
            <Image
              src="https://res.cloudinary.com/dyozdezpg/image/upload/v1681208217/Fountainpay-V2_Assets/Group_4212_xangux.svg"
              width="100%"
            />
          </Card>
          <Title title="Do the math" color={white} center />
          <Card flex flexdirection="column" gap="20px" alignitems="center">
            <Card
              radius="5px"
              bg="rgba(0, 77, 136, 1)"
              padding="25px"
              smpadding="10px"
              flex
              alignitems="center"
            >
              <TitleText
                size="40px"
                lineheight="54px"
                color={white}
                heavy
                mdsize="30px"
                smsize=""
              >
                NGN
              </TitleText>
              <TextField
                color={white}
                fontsize="40px"
                heavy
                bg="none"
                noborder
                width="560px"
                mdwidth="unset"
                smwidth="100%"
                mdfontsize="30px"
                smfontsize="30px"
                value={price}
                onChange={(e: any) => setPrice(e.target.value)}
              />
            </Card>
            <WhoBearsCharge
              navController={navController}
              setNavController={setNavController}
            />
            <WhatYouGet
              navController={navController}
              setNavController={setNavController}
              youGet={youGet}
              fountainpayGet={fountainpayGet}
            />
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default DoTheMath;
