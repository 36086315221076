import MainText from "../../../ui/mainText";
import { Card, InnerSection, MainSection } from "../../../ui/styles";
import Title from "../../../ui/title";
import PaymentCards from "./PaymentCards";

const PaymentCollection = () => {
  return (
    <MainSection width="100%" mtop="100px" smmargin="70px 0 0" data-aos="fade-up">
      <InnerSection>
        <Card flex flexdirection="column" gap="25px">
          <Title
            title="Payment collection made simple and secure for"
            span="every business"
            width="1130px"
          />
          <Card bdleft="7px solid #219653" padding="0 0 0 7px">
            <MainText
              text="Give your customers a reliable and secured option to make payments by using our easy and secure payment gateway."
              width="750px"
            />
          </Card>
        </Card>
        <PaymentCards />
      </InnerSection>
    </MainSection>
  );
};

export default PaymentCollection;
