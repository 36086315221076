import { black, Card, Image, Text, TitleText } from "../styles";

interface IDetailedProps {
  icon?: string;
  title?: string;
  width?: string;
  text?: string;
}

const DetailedListType = (props: IDetailedProps) => {
  const { icon, text, title, width } = props;
  return (
    <Card
      flex
      flexdirection="column"
      gap="10px"
      width={width}
      smwidth="100%"
      data-aos="fade-up"
    >
      <Image src={icon} width="32px" alt={title} />
      <TitleText
        size="24px"
        lineheight="32px"
        weight="600"
        smsize="18px"
        smlineheight="24px"
        color={black}
      >
        {title}
      </TitleText>
      <Text size="14px" lineheight="21px" color={black}>
        {text}
      </Text>
    </Card>
  );
};

export default DetailedListType;
