import { useState } from "react";
import { faqDetails } from "../../../../data";
import { Card, Image, Text, TitleText, white } from "../../../ui/styles";

const FAQs = () => {
  const [clickedIndex, setIsClickedIndex] = useState(faqDetails.length + 2);
  const handleClicked = (index: any) => {
    if (clickedIndex === index) {
      setIsClickedIndex(faqDetails.length + 2);
    } else {
      setIsClickedIndex(index);
    }
  };
  return (
    <Card flex flexdirection="column" gap="20px">
      {faqDetails.map((faq, index) => (
        <Card
          flex
          flexdirection="column"
          key={index}
          width="100%"
          alignitems="center"
          pointer
          onClick={() => handleClicked(index)}
        >
          <Card
            flex
            justifycontent="space-between"
            padding="20px"
            width="100%"
            bg={white}
            btop="0.5px solid #D7D7D7"
            bbottom="0.5px solid #D7D7D7"
          >
            <TitleText
              heavy
              size="18px"
              smsize="14px"
              lineheight="24.48px"
              smlineheight="20px"
            >
              {index + 1}. {faq.question}
            </TitleText>
            <Image
              src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680774886/Fountainpay-V2_Assets/Vector_crlv1k.svg"
              alt="plus"
            />
          </Card>
          {clickedIndex === index && (
            <Card
              flex
              justifycontent="space-between"
              padding="20px"
              width="100%"
              bg={white}
              btop="0.5px solid #D7D7D7"
              bbottom="0.5px solid #D7D7D7"
            >
              <Text
                size="14px"
                smsize="14px"
                lineheight="21px"
                smlineheight="20px"
              >
                {faq.answer}
              </Text>
            </Card>
          )}
        </Card>
      ))}
    </Card>
  );
};

export default FAQs;
