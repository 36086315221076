import React from "react";
import DetailedListType from "../../../ui/detailedListType";
import { Card } from "../../../ui/styles";

const missionDetails = [
  {
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680781946/Fountainpay-V2_Assets/Group_4065_rj5eeo.svg",
    title: "Mission",
    text: "To provide dependable, secure financial services and payment solutions that will benefit individuals and businesses.",
  },
  {
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680781946/Fountainpay-V2_Assets/Group_4063_g0wxrq.svg",
    title: "Vision",
    text: "Becoming the best in the world by providing secure, seamless payment solutions.",
  },
];

const LeftDiv = () => {
  return (
    <>
      <Card flex flexdirection="column" gap="40px">
        {missionDetails.map((mission, index) => (
          <DetailedListType
            key={index}
            title={mission.title}
            text={mission.text}
            icon={mission.icon}
            width="450px"
          />
        ))}
      </Card>
    </>
  );
};

export default LeftDiv;
