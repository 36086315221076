import GetStarted from "./GetStarted";
import MainFooter from "./mainFooter/MainFooter";

const Footer = () => {
  return (
    <>
      {/* <GetStarted /> */}
      <MainFooter />
    </>
  );
};

export default Footer;
