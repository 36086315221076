import {
  black,
  Button,
  Card,
  InnerSection,
  LinkText,
  MainSection,
  primaryRed,
  TitleText,
  white,
} from "../../styles";

const GetStarted = () => {
  return (
    <MainSection width="100%" mtop="100px" mbottom="100px" smmargin="70px 0">
      <InnerSection>
        <Card
          flex
          justifycontent="space-between"
          mdflexdirection="column"
          smwidth="100%"
          gap="15px"
          alignitems="center"
          padding="50px 34px"
          smpadding="40px 20px"
          border={`1px solid ${primaryRed}`}
          radius="10px"
          bg={white}
        >
          <TitleText
            size="32px"
            width="490px"
            mdwidth="100%"
            heavy
            lineheight="43px"
            color={black}
            mdsize="48px"
            mdlineheight="55px"
            smsize="20px"
            smlineheight="35px"
            mdtextalign="center"
          >
            Start accepting payments in 5 minutes
          </TitleText>
          <LinkText to="/coming-soon/">
            <Button
              size="15px"
              lineheight="20px"
              bg={primaryRed}
              color={white}
              padding="13px 56px"
            >
              Get Started
            </Button>
          </LinkText>
          {/* <a href="https://app.fountainpay.ng/" target="_blank">
            <Button
              size="15px"
              lineheight="20px"
              bg={primaryRed}
              color={white}
              padding="13px 56px"
            >
              Get Started
            </Button>
          </a> */}
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default GetStarted;
