import { navDetails } from "../../../../../data";
import {
  black,
  Button,
  Card,
  Divider,
  Image,
  InnerSection,
  LinkText,
  MainSection,
  deepBlue,
  Text,
  TitleText,
  white,
} from "../../../styles";

const MobileNavigator = () => {
  return (
    <Card
      width="100%"
      height="100%"
      bg={white}
      position="fixed"
      top="0"
      left="0"
      margin="60px 0 0"
      smmargin="100px 0 0"
      index="100"
      flex
      // justifycontent="center"
    >
      <Card flex flexdirection="column" width="512px" smwidth="100%">
        {navDetails.map((nav, index) => (
          <Card key={index}>
            {nav.dropdown ? (
              <Card padding="25px" flex flexdirection="column" gap="20px">
                <Text size="13px" color="#606060">
                  {nav.navMain}
                </Text>
                <Card display="grid" gridcolumn="1fr" gap="20px">
                  {nav.subNav.map((subNav, index) => (
                    <LinkText
                      to={subNav.handle}
                      key={index}
                      display="flex"
                      alignitems="center"
                      color={black}
                    >
                      <Image
                        src={subNav.icon}
                        width="24px"
                        margin="0 10px 0 0"
                      />
                      <TitleText size="16px">{subNav.nav}</TitleText>
                    </LinkText>
                  ))}
                </Card>
              </Card>
            ) : (
              <LinkText
                to={nav.handle}
                padding="20px 25px"
                size="16px"
                color={black}
                weight="500"
              >
                {nav.navMain}
              </LinkText>
            )}
            <Divider width="200vw" color="#E5E5E5" />
          </Card>
        ))}
        <MainSection width="100%" position="absolute" bottom="96px">
          <InnerSection>
            <a href="https://app.fountainpay.ng/" target="_blank">
              <Button
                width="100%"
                color={white}
                bg={deepBlue}
                size="14px"
                padding="13px"
                radius="8px"
                index="10001"
              >
                Sign in
              </Button>
            </a>
          </InnerSection>
        </MainSection>
      </Card>
    </Card>
  );
};

export default MobileNavigator;
