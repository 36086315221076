import { Card, Image, InnerSection, MainSection } from '../../../ui/styles'
import RightDiv from './RightDiv'

const WhatWeStandFor = () => {
  return (
    <MainSection
      width="100%"
      mtop="100px"
      smmargin="70px 0 0"
      data-aos="fade-up"
    >
      <InnerSection>
        <Card
          flex
          mdflexdirection="column"
          justifycontent="space-between"
          alignitems="center"
          gap="50px"
          smpadding="50px 0 0"
        >
          <Image
            src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680781948/Fountainpay-V2_Assets/Group_4158_v05dnx.svg"
            alt="fountainpay"
            smwidth='90%'
          />
          <RightDiv />
        </Card>
      </InnerSection>
    </MainSection>
  )
}

export default WhatWeStandFor