import React from 'react'
import MainText from '../../../ui/mainText'
import PageIntro from '../../../ui/pageIntro'
import { black, Card, InnerSection, MainSection, primaryRed, TitleText } from '../../../ui/styles'

const AboutHeroSection = () => {
  return (
    <MainSection
      width="100%"
      padding="100px 0 0"
      mdpadding="70px 0 0"
      data-aos="fade-up"
    >
      <InnerSection>
        <Card flex gap="25px" flexdirection="column" alignitems="center">
          <PageIntro text="Who we are" />
          <TitleText
            size="54px"
            lineheight="73.44px"
            width="900px"
            mdwidth="100%"
            mdsize="50px"
            mdlineheight="65px"
            smsize="30px"
            smlineheight="40px"
            weight="600"
            color={black}
            center
          >
            Securing every {" "}
            <span style={{ color: `${primaryRed}` }}>transaction</span>
          </TitleText>
          <Card margin="-13px 0 0">
            <MainText
              text="Secure and fast payment made simple for every business."
              center
            />
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  )
}

export default AboutHeroSection