import { paymentProcessingDetails } from "../../../../data";
import ButtonStyle from "../../../ui/button";
import ProcessCard from "../../../ui/processCard";
import {
  black,
  Card,
  gray,
  InnerSection,
  LinkText,
  MainSection,
  Text,
  TitleText,
} from "../../../ui/styles";
import Title from "../../../ui/title";

const PaymentProcessing = () => {
  return (
    <MainSection
      width="100%"
      mtop="100px"
      smmargin="70px 0 0"
      data-aos="fade-up"
    >
      <InnerSection>
        <Card flex flexdirection="column" gap="50px" smgap="20px">
          <Title title="Payment Processing" span="Features" width="623px" />
          <Card
            flex
            justifycontent="space-between"
            mddisplay="grid"
            mdgridcolumn="1fr 1fr"
            smdisplay="flex"
            smflexdirection="column"
            mdgap="20px"
          >
            {paymentProcessingDetails.map((detail, index) => (
              <ProcessCard
                width="290px"
                bg={detail.bg}
                border={detail.border}
                title={detail.title}
                text={detail.text}
                key={index}
              />
            ))}
          </Card>
          <Card margin="0 auto">
            <LinkText to="/coming-soon/">
              <ButtonStyle text="Get Started" width="fit-content" />
            </LinkText>
            {/* <a href="https://app.fountainpay.ng/" target="_blank">
              <ButtonStyle text="Get Started" width="fit-content" />{" "}
            </a> */}
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default PaymentProcessing;
