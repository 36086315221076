import { Card, Image, Text, white } from "../../../ui/styles";

interface IWhoProps {
  navController?: any;
  setNavController?: any;
}

const WhoBearsCharge = (props: IWhoProps) => {
  const { navController, setNavController } = props;
  return (
    <Card margin="13px 0 0" flex gap="35px" alignitems="center" smflexdirection="column" smgap="10px">
      <Card flex alignitems="center" gap="5px">
        <Image src="" width="12px" />
        <Text heavy size="12px" color={white}>
          Who bears the Charges:
        </Text>
      </Card>
      <Card flex gap="50px" smgap="20px">
        <Card
          flex
          alignitems="center"
          gap="5px"
          onClick={() => setNavController("customer")}
          pointer
        >
          <Text size="12px" color={white}>
            Customer
          </Text>
          <input
            type="radio"
            checked={navController === "customer" && true}
            style={{ accentColor: "white" }}
          />
        </Card>
        <Card
          flex
          alignitems="center"
          gap="5px"
          onClick={() => setNavController("business")}
          pointer
        >
          <Text size="12px" color={white}>
            My Business
          </Text>
          <input
            type="radio"
            checked={navController === "business" && true}
            style={{ accentColor: "white" }}
          />
        </Card>
      </Card>
    </Card>
  );
};

export default WhoBearsCharge;
