import { Card, Image, primaryRed, Text } from "../styles";

interface IPageIntroProps {
  text?: string;
  icon?: string;
}

const PageIntro = (props: IPageIntroProps) => {
  const { text, icon } = props;
  return (
    <Card flex gap="12px" alignitems="center">
      <Text size="16px" lineheight="24px" color={primaryRed} heavy>
        {text}
      </Text>
      <Image src={icon} />
    </Card>
  );
};

export default PageIntro;
