import { Card, Image } from "../../../ui/styles";

const RightDiv = () => {
  return (
    <Card flex flexdirection="column" gap="32px" mddisplay="none">
      <Image src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680609001/Fountainpay-V2_Assets/Mask_group_i1cle1.png" />
      <Image src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680609001/Fountainpay-V2_Assets/FP_2_bsl8my.png" radius="24px" />
    </Card>
  );
};

export default RightDiv;
