import {
  black,
  Card,
  deepBlue,
  Image,
  LinkText,
  Text,
  TitleText,
} from "../../../../styles";

interface navProps {
  nav?: string;
  id?: any;
  handle?: any;
  detail?: any;
  icon?: string;
}

const NavDropdown = (props: navProps) => {
  const { handle, nav, detail, icon } = props;
  return (
    <Card>
      <LinkText
        display="flex"
        gap="10px"
        alignitems="center"
        padding="15px 30px"
        width="100%"
        to={handle}
      >
        <Image src={icon} width="24px" />
        <Card flex flexdirection="column" gap="">
          <TitleText size="14px" lineheight="21px" color={black}>
            {nav}
          </TitleText>
          {detail !== false && (
            <Text size="14px" lineheight="21px" color={black}>
              {detail}
            </Text>
          )}
        </Card>
      </LinkText>
    </Card>
  );
};

export default NavDropdown;
