import React from "react";
import { Card, Image, InnerSection, MainSection } from "../../../ui/styles";
import LeftDiv from "./LeftDiv";

const Mission = () => {
  return (
    <MainSection width="100%" mtop="100px" smmargin="70px 0 0" data-aos="fade-up">
      <InnerSection>
        <Card
          flex
          mdflexdirection="column-reverse"
          justifycontent="space-between"
          alignitems="center"
          gap="50px"
          smpadding="50px 0 0"
        >
            <LeftDiv />
            <Image
            src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680781949/Fountainpay-V2_Assets/Group_go5zaw.svg"
            alt="fountainpay"
            smwidth='90%'
          />
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default Mission;
