import React from "react";
import MainText from "../../../ui/mainText";
import { black, Card, TitleText, white } from "../../../ui/styles";

const paymentCardsDetails = [
  {
    title: "Easy Business",
    text: "Manage and track team member transaction easily.",
  },
  {
    title: "Split Payment",
    text: "Use a single payment link to settle payments for multiple accounts in one transaction.",
  },
  {
    title: "Track Expenses",
    text: "Manage your payment and transactions from anywhere.",
  },
  {
    title: "Accept Payment",
    text: "Send instant payment links to your customers and get your settlement in less than 24 hours.  ",
  },
];

const PaymentCards = () => {
  return (
    <Card
      flex
      justifycontent="space-between"
      gap="32px"
      mddisplay="grid"
      mdgridcolumn="1fr 1fr"
      smdisplay="flex"
      smflexdirection="column"
      margin="100px 0 0"
      smmargin="50px 0 0"
      data-aos="fade-up"
    >
      {paymentCardsDetails.map((detail, index) => (
        <Card
          flex
          flexdirection="column"
          flexratio="1"
          key={index}
          padding="30px 25px"
          gap="20px"
          bg={white}
          radius="10px"
          shadow="0px 4px 50px rgba(0, 0, 0, 0.05);"
        >
          <TitleText
            size="24px"
            lineheight="32px"
            weight="600"
            smsize="20px"
            smlineheight="28px"
            color={black}
          >
            {detail.title}
          </TitleText>
          <MainText text={detail.text} />
        </Card>
      ))}
    </Card>
  );
};

export default PaymentCards;
