import React, { useState } from "react";
import {
  black,
  Button,
  Card,
  Divider,
  InnerSection,
  InputLabel,
  MainSection,
  primaryRed,
  SelectField,
  Text,
  TextAreaField,
  TextField,
  TitleText,
  white,
} from "../../ui/styles";
import FileInput from "../fileUpload";
import api from "../../../helper/api";
import { toast } from "react-toastify";

import Close from "../fileUpload/close.png"


const HelpForm = () => {
  const [category, setCategory] = useState<string>("Transactions");
  const [department, setDepartment] = useState<string>("General Support");
  const [product, setProduct] = useState<string>("Payment Gateway");
  const [subject, setSubject] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [detail, setDetail] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<any>([]);

  const [isSending, setIsSending] = useState<boolean>(false);
  const [isMsgcardClosed, setIsMsgcardClosed] = useState<boolean>(false);
  const [statusType, setStatusType] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleFileUpload=(files: any)=>{
    setUploadedFile(files)
  };

  const postHelp=async()=>{
    if(!category || !department || !product || !subject || !email || !detail){
      setError("All fields must be dully completed.")
      setStatusType(true)
      setIsMsgcardClosed(true)
      return;
    }
    setIsSending(true)
    try{
      let formData = new FormData();
      formData.append("category", category)
      formData.append("department", department)
      formData.append("product", product)
      formData.append("subject", subject)
      formData.append("email", email)
      formData.append("detail", detail)
      formData.append("files", uploadedFile)

      let response = await api.post('/shared/general-dispute', formData);
      setError(response.message)
      if(response.status){
        setStatusType(false)
        setIsMsgcardClosed(true)
        setError("Your complaint was sent successfully.")
        toast.success("Your complaint was sent successfully.")
        setCategory('Transactions')
        setDepartment("General Support")
        setProduct("Payment Gateway")
        setSubject("")
        setEmail("")
        setDetail("")
        setUploadedFile([])
        
      }else{
        setStatusType(true)
        setIsMsgcardClosed(true)
      }
    }catch(err: any){
      setStatusType(true)
    }finally{
      setIsSending(false)
    }
    
  }


  return (
    <MainSection width="100%" mtop="100px">
      
      <InnerSection>
        {isMsgcardClosed &&
          <Card
            padding="10px 25px"
            bg={!statusType? "#d1ebe3": "#ddc7d0"}
            color={!statusType? "green": "pink"}
            shadow="0px 4px 50px rgba(0, 0, 0, 0.05)"
            margin="0px 0px 20px 0px"
            flex flexdirection="row" gap="10px" wrap width="100%" justifycontent="space-between"
          >
            <TitleText size="20px" color="grey">{error}</TitleText>

            <img src={Close} width="20px" height="20px" style={{cursor: "pointer"}} onClick={()=>setIsMsgcardClosed(false)} />
          </Card>
        }
        
        <Card
          padding="57px 112px"
          smpadding="10px 10px"
          btop={`4px solid ${primaryRed}`}
          bg={white}
          shadow="0px 4px 50px rgba(0, 0, 0, 0.05)"
          smwidth="100%"
        >
          <Card flex flexdirection="column" smwidth="100%" gap="10px" color={black}>
            <TitleText size="36px" lineheight="43px">
              Dispute Resolution
            </TitleText>
            <TitleText size="20px" color="grey">
              Kindly note that all fields are required.
            </TitleText>
            <Divider color="#ccced1" size="1px" top="30px" bottom="10px" />
            
          </Card>
          <Card flex flexdirection="row" gap="10px" wrap width="100%" smwidth="100%" justifycontent="space-between" margin="50px 0px">
            <Card flex flexdirection="column" gap="10px" width="31.5%" smwidth="100%">
              <InputLabel htmlFor="name">Category</InputLabel>
              <SelectField radius="5px" bgcolor="#FDEFF0" name="name" height="60px" onChange={(e: any)=>setCategory(e.target.value)}>
                <option value="Transactions">Transactions</option>
                <option value="Settlements">Settlements</option> 
                <option value="Billings">Billings</option> 
                <option value="Authentication">Authentication</option> 
                <option value="Oboarding">Oboarding</option> 
                <option value="Therminal acquisition">Therminal acquisition</option> 
                <option value="Integrations">Integrations</option> 
                <option value="2Factor Uthentication">2Factor Uthentication</option> 
                <option value="Other Technical Issues">Other Technical Issues</option> 
              </SelectField>
            </Card>

            <Card flex flexdirection="column" gap="10px" width="31.5%" smwidth="100%">
              <InputLabel htmlFor="name">Select unit</InputLabel>
              <SelectField radius="5px" bgcolor="#FDEFF0" name="name" height="60px" onChange={(e: any)=>setDepartment(e.target.value)}>
                <option value="General Support">General Support</option>
                <option value="Control and Compliance">Control and Compliance</option> 
                <option value="Billing">Billing</option> 
                <option value="Technical Support">Technical Support</option>
              </SelectField>
            </Card>

            <Card flex flexdirection="column" gap="10px" width="31.5%" smwidth="100%">
              <InputLabel htmlFor="name">Select product</InputLabel>
              <SelectField radius="5px" bgcolor="#FDEFF0" name="name" height="60px" onChange={(e: any)=>setProduct(e.target.value)}>
                <option value="Payment Gateway">Payment Gateway</option>
                <option value="Agency Banking">Agency Banking</option> 
                <option value="Payrev">Payrev</option> 
                <option value="Salari">Salari</option> 
                <option value="Others">Others</option>
              </SelectField>
            </Card>
          </Card>
          
          <Card flex flexdirection="row" gap="10px" wrap width="100%" justifycontent="space-between"  margin="50px 0px" smwidth="100%">
            <Card flex flexdirection="column" gap="10px" width="60%" smwidth="100%">
              <InputLabel htmlFor="name">Subject/Title</InputLabel>
              <TextField radius="5px" bgcolor="#FDEFF0" name="name" height="60px" onChange={(e: any)=>setSubject(e.target.value)} />
            </Card>

            <Card flex flexdirection="column" gap="10px" width="37%" smwidth="100%">
              <InputLabel htmlFor="name">Your email </InputLabel>
              <TextField type="email" radius="5px" bgcolor="#FDEFF0" name="name" height="60px" onChange={(e: any)=>setEmail(e.target.value)} />
            </Card>
          </Card>
          <Card flex flexdirection="row" gap="10px" wrap width="100%" justifycontent="space-between"  margin="50px 0px" smwidth="100%">
            <Card flex flexdirection="column" gap="10px" width="100%" smwidth="100%">
              <InputLabel htmlFor="name">Issue description (Max: 500 characters allowed)</InputLabel>
              <TextAreaField radius="5px" height="140px" bgcolor="#FDEFF0"  name="name" border="rgba(130, 130, 130, 0.9)" onChange={(e: any)=>setDetail(e.target.value)} />
            </Card>
          </Card>

          <Card flex flexdirection="row" gap="10px" wrap width="100%" justifycontent="space-between"  margin="30px 0px" smwidth="100%">
            <FileInput width="100%" onFileUpload={handleFileUpload} />
          </Card>

          <Card flex flexdirection="row" gap="10px" wrap width="100%" justifycontent="space-between"  margin="50px 0px" smwidth="100%">
            <Button
                size="22px"
                lineheight="20px"
                bg={primaryRed}
                color={white}
                padding="13px 56px"
                height="60px"
                onClick={postHelp}
                disabled={isSending}
              >
                {isSending ? "Sending..." : "Submit"}
                
            </Button>
          </Card>
        </Card>

       
      </InnerSection>
      
    </MainSection>
  );
};

export default HelpForm;
