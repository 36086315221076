import React, { useState } from "react";
import { Card, Image } from "../../../../ui/styles";

const heroImage = [
  "https://res.cloudinary.com/dyozdezpg/image/upload/v1682497096/Fountainpay-V2_Assets/WhatsApp_Image_2023-04-19_at_12.08_2_wda8ty.svg",
  "https://res.cloudinary.com/dyozdezpg/image/upload/v1682497112/Fountainpay-V2_Assets/IMG-8033_1_lgkmol.svg",
  "https://res.cloudinary.com/dyozdezpg/image/upload/v1682497104/Fountainpay-V2_Assets/IMG-8030_2_nlijw5.svg",
  "https://res.cloudinary.com/dyozdezpg/image/upload/v1682497101/Fountainpay-V2_Assets/IMG-8031_1_qfl0rb.svg",
  "https://res.cloudinary.com/dyozdezpg/image/upload/v1682497096/Fountainpay-V2_Assets/WhatsApp_Image_2023-04-19_at_12.08_2_wda8ty.svg",
  "https://res.cloudinary.com/dyozdezpg/image/upload/v1682497102/Fountainpay-V2_Assets/IMG-7985_2_l9p8mf.svg",
];

const HeroImage = () => {
  const [hover, setHover] = useState(null);

  const toggle = (index: any) => {
    if (hover === index) {
      return setHover(null);
    }

    setHover(index);
  };
  return (
    <>
      <Card
        flex
        gap="16px"
        justifycontent="center"
        alignitems="center"
        margin="100px 0 0"
        overflow="hidden"
      >
        {heroImage.map((image, index) => (
          <Card
            mddisplay="none"
            flex
            justifycontent="center"
            alignitems="center"
            width={hover === index ? "596px" : "190px"}
            overflow="hidden"
            radius="20px"
            transition="0.2s ease-in-out"
            onMouseEnter={() => toggle(index)}
            onMouseLeave={() => toggle(index)}
            pointer
          >
            <Image src={image} />
          </Card>
        ))}
      </Card>
      <Card display="none" mddisplay="block">
        <Image src="https://res.cloudinary.com/dyozdezpg/image/upload/v1681938246/Fountainpay-V2_Assets/hero-image_yeqvzt.svg" />
      </Card>
    </>
  );
};

export default HeroImage;
