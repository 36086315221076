import styled, { keyframes } from "styled-components";
import { MainSection } from "../styles";

const animateOnSlide = keyframes`
  0%   { left: 88px; }
  30% { left: 1147px; }
  50% { left: 1147px; }
  80% { left: 88px; }
  100%   { left: 88px; }
`;
const animateOnSlide2 = keyframes`
  0%   { left: 1147px; }
  30% { left: 88px; }
  50% { left: 88px; }
  80% { left: 1147px; }
  100%   { left: 1147px; }
`;

const AnimationCard1 = styled("div")`
  animation: ${animateOnSlide} 3s infinite ease-in-out;
  position: absolute;
  width: 224px;
  height: 224px;
  background: rgba(234, 19, 19, 0.4);
  filter: blur(150px);
  left: 88px;
  margin: 0 auto;
  top: 178px;
  z-index: 10;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;
const AnimationCard2 = styled("div")`
  animation: ${animateOnSlide2} 3s infinite ease-in-out;
  position: absolute;
  width: 224px;
  height: 224px;
  background: rgba(234, 19, 19, 0.4);
  filter: blur(150px);
  left: 1147px;
  margin: 0 auto;
  top: 279px;
  z-index: 10;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const HeroAnimation = () => {
  return (
    <>
      <MainSection
        smdisplay="none"
        width="100%"
        maxwidth="1440px"
        overflow="hidden"
        position="relative"
        top="0"
        left="0"
        index="-1"
      >
        <AnimationCard1 />
        <AnimationCard2 />
      </MainSection>
    </>
  );
};

export default HeroAnimation;
