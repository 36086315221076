import { Card, Image } from "../../../ui/styles";

const HeroImage = () => {
  return (
    <Card position="relative" height="230px" width="100%" smdisplay="none">
      <Image
        src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680708697/Fountainpay-V2_Assets/Group_tzj1hs.svg"
        alt="fountainpay"
        position="absolute"
        top="-50px"
        left="68px"
      />
      <Image
        src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680708698/Fountainpay-V2_Assets/Group_2_mg4mnt.svg"
        alt="fountainpay"
        position="absolute"
        top="-100px"
        right="90px"
      />
    </Card>
  );
};

export default HeroImage;
