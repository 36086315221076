import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import ScrollToTop from "./scrollToTop/ScrollToTop";
import { useEffect, useState } from "react";
import Aos from "aos";

import "aos/dist/aos.css";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Cookie from "./pages/Cookie";

function App() {
  const[show, setShow]=useState(true)
  useEffect(() => {
    var cook: any=localStorage.getItem('cookie');
    cook=cook?JSON.parse(cook): {accept: true};
    setShow(cook.accept)
    Aos.init({
      offset: 100,
      duration: 600,
      easing: "ease-in-sine",
    });
  }, []);
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <AppRoutes />
        <ToastContainer />
        {show &&
          <Cookie />
        }
        
      </Router>
    </div>
  );
}

export default App;
