import ProcessCard from "../../../../ui/processCard";
import { Card, Image } from "../../../../ui/styles";

const bankingFeaturesDetails = [
  {
    title: "Transactions",
    text: "Transaction features aid transparency by showing daily, weekly, monthly, and yearly transactions and also filter transactions for better insight. ",
    top: "-20px",
    left: "424px",
    bg: "rgba(248, 110, 127, 0.09)",
    height: "219px",
    border: "#F86E7F",
  },
  {
    title: "Inventories",
    text: "Track every POS transaction with the inventory feature. ",
    top: "315px",
    right: "40px",
    bg: "rgba(118, 0, 0, 0.09)",
    height: "219px",
    border: "#760000",
  },
  {
    title: "Subagents",
    text: "Transaction features aid transparency by showing daily, weekly, monthly, and yearly transactions and also filter transactions for better insight. ",
    top: "315px",
    left: "40px",
    bg: "rgba(7, 136, 90, 0.09)",
    height: "219px",
    border: "#000000",
  },
  {
    title: "Geolocation",
    text: "Geolocation allows you to locate the closest available POS agent to your location. ",
    top: "710px",
    left: "424px",
    bg: "rgba(39, 59, 74, 0.09)",
    height: "188px",
    border: "#273B4A",
  },
];

const Features = () => {
  return (
    <Card
      height="920px"
      mdheight="unset"
      mddisplay="grid"
      gridcolumn="1fr 1fr"
      smdisplay="grid"
      smgridcolumn="1fr"
      mdgap="20px"
      flex
      justifycontent="center"
      alignitems="center"
      position="relative"
      width="100%"
    >
      {bankingFeaturesDetails.map((feature, index) => (
        <ProcessCard
          key={index}
          bg={feature.bg}
          absolute
          border={feature.border}
          top={feature.top}
          right={feature.right}
          left={feature.left}
          text={feature.text}
          title={feature.title}
          height={feature.height}
          width="392px"
        />
      ))}
      <Card
        width="506px"
        height="506px"
        radius="50%"
        bg="rgba(248, 110, 127, 0.09)"
        flex
        justifycontent="center"
        alignitems="center"
        mddisplay="none"
      >
        <Card
          width="318px"
          height="318px"
          radius="50%"
          bg="rgba(118, 0, 0, 1)"
          mddisplay="none"
          flex
          justifycontent="center"
          alignitems="center"
        >
          <Image src="https://res.cloudinary.com/dyozdezpg/image/upload/v1681223329/Fountainpay-V2_Assets/Group_4155_owqkzi.png" width="244px" />
        </Card>
      </Card>
    </Card>
  );
};

export default Features;
