import { useEffect, useState } from "react";
import { testimonialDetails } from "../../../../data";
import {
  Card,
  InnerSection,
  MainSection,
  primaryRed,
  Text,
} from "../../../ui/styles";
import Title from "../../../ui/title";
import TestimonialBody from "./testimonialBody/TestimonialBody";
import TestimonialPagination from "./TestimonialPagination";

const Testimonials = () => {
  const [changingIndex, setChangingIndex] = useState(0);

  useEffect(() => {
    const newIndex =
      changingIndex > testimonialDetails.length - 2 ? 0 : changingIndex + 1;

    const timer = setTimeout(() => {
      setChangingIndex(newIndex);
    }, 5000);

    return () => clearTimeout(timer);
  }, [changingIndex]);

  return (
    <MainSection width="100%" mtop="100px" smmargin="70px 0 0" data-aos="fade-up">
      <InnerSection>
        <Card flex flexdirection="column" gap="10px">
          <Card
            border={`0.2px solid ${primaryRed}`}
            radius="10px"
            padding="13px 37px"
            flex
            gap="10px"
            alignitems="center"
            width="fit-content"
            margin="0 auto"
          >
            <Card width="16px" height="16px" radius="50%" bg={primaryRed} />
            <Text size="18px" lineheight="27px" weight="300">
              Testimonials
            </Text>
          </Card>
          <Title title="Feedback From Our Users" center />
          <Card
            flex
            justifycontent="space-between"
            alignitems="center"
            mdflexdirection="column"
            margin="40px 0 0"
          >
            {testimonialDetails.map(
              (testimonial, index) =>
                changingIndex === index && (
                  <TestimonialBody changingIndex={changingIndex} key={index} />
                )
            )}
            <TestimonialPagination
              changingIndex={changingIndex}
              setChangingIndex={setChangingIndex}
            />
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default Testimonials;
