import { useState } from "react";
import { Card } from "../../ui/styles"
import { FileLists, FileUpload } from "./Style"

import UploadIcon from "./upload.png";
import { toast } from "react-toastify";

export interface IFileUploadeProps {
    width?: string;
    height?: string;
    onFileUpload: Function;
  }

const FileInput=(props: IFileUploadeProps)=>{
    const [selectedFile, setSelectedFile] = useState<any>([]);
    const [selectedName, setSelectedName] = useState("");


    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file.size > 10000000) {
            return toast.error(
              "Please slected file should have a maximum size of 10MB."
            );
        }
        const fileLists=selectedFile;
        let newUpload: any = [...selectedFile, file];
        setSelectedFile(newUpload);
        //setSelectedName(file.name);
        props.onFileUpload(selectedFile)
        // Additional validation logic
    };


    return(
        <Card width="100%" justifycontent="space-between"  margin="50px 49px 50px 0px">
            <FileUpload width={props.width}  height={props.height}>
                <img src={UploadIcon} alt="upload" />
                <h3>Click box to upload</h3>
                <p>Maximun file size 10mb</p>
                <input type="file" onChange={handleFileChange} />
            </FileUpload>    
            <FileLists>
                {selectedFile && selectedFile.length>0 && 
                    selectedFile.map((item: any, index: number) => {
                        return (
                            <p>
                                <span>{item.name.toString().replace(/[_-]/g, ' ')}</span>
                            </p>
                        );
                    })
                }
            </FileLists>
        </Card>
        
    )
}

export default FileInput;