import { black, Card, Image, Text } from "../styles";

const ListType = (props: { text: string; invertStyle?: boolean; noIcon?: any; }) => {
  const { text, invertStyle, noIcon } = props;
  return (
    <Card flex alignitems="center" gap="10px">
      <Image
        src={
          invertStyle
            ? "https://res.cloudinary.com/dyozdezpg/image/upload/v1680787543/Fountainpay-V2_Assets/Group_4256_xcircm.svg"
            : "https://res.cloudinary.com/dyozdezpg/image/upload/v1680616699/Fountainpay-V2_Assets/Group_4212_tal3yz.svg"
        }
        width="24px"
        mddisplay={noIcon && "none"}
        smdisplay={noIcon && "none"}
      />
      <Text size="14px" lineheight="21px" color={black} weight="400">
        {text}
      </Text>
    </Card>
  );
};

export default ListType;
