import ButtonStyle from "../../button";
import {
  Button,
  Card,
  deepBlue,
  Image,
  InnerSection,
  LinkText,
  MainSection,
  white,
} from "../../styles";
import MobileHeader from "./mobileHeader/MobileHeader";
import NavigationSection from "./navigationSection/NavigationSection";

const Header = () => {
  return (
    <>
      <MainSection width="100%">
        <InnerSection>
          <Card
            flex
            justifycontent="space-between"
            alignitems="center"
            padding="24px 0"
            mddisplay="none"
          >
            <LinkText to="/">
              <Image
                src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680257310/Fountainpay-V2_Assets/FountainPay_Logo_wg2i2c_1_xftv3z.png"
                alt="fountainpay-logo"
                width="190px"
              />
            </LinkText>
            <Card flex alignitems="center" gap="43px">
              <NavigationSection />
              <a href="https://app.fountainpay.ng/" target="_blank">
                <ButtonStyle text="Sign in" invertStyle />
              </a>
            </Card>
          </Card>
        </InnerSection>
      </MainSection>
      <MobileHeader />
    </>
  );
};

export default Header;
