import { useState } from "react";
import { navDetails } from "../../../../../data";
import { black, Card, LinkText, Text, white } from "../../../styles";
import NavDropdown from "./navDropdown/NavDropdown";

const NavigationSection = () => {
  const [hover, setHover] = useState(null);

  const toggle = (index: any) => {
    if (hover === index) {
      return setHover(null);
    }

    setHover(index);
  };

  return (
    <Card
      height="30px"
      flex
      gap="27px"
      justifycontent="center"
      alignitems="center"
    >
      {navDetails.map((navs, index) =>
        navs.dropdown ? (
          <Card
            position="relative"
            key={index}
            onMouseEnter={() => toggle(index)}
            onMouseLeave={() => toggle(index)}
            pointer
            index="15"
          >
            <Card flex alignitems="center" gap="7px" justifycontent="center">
              <Text size="16px" lineheight="24px">
                {navs.navMain}
              </Text>{" "}
              <i
                className="fa-solid fa-angle-down"
                style={{ fontSize: "15px" }}
              />
            </Card>
            {hover === index && (
              <Card position="absolute" top="15px" index="10">
                <Card
                  radius="15px"
                  padding="15px 0"
                  flex
                  flexdirection="column"
                  margin="15px 0 0"
                  border="0.5px solid rgba(0, 0, 0, 0.1)"
                  shadow="40px 40px 40px rgba(0, 0, 0, 0.1)"
                  color={black}
                  bg={white}
                  width="250px"
                >
                  {navs.subNav.map((subNavitem, index) => (
                    <NavDropdown
                      key={index}
                      handle={subNavitem.handle}
                      nav={subNavitem.nav}
                      icon={subNavitem.icon}
                    />
                  ))}
                </Card>
              </Card>
            )}
          </Card>
        ) : navs.external ? (
          <a
            key={index}
            href={navs.handle}
            style={{
              textDecoration: "none",
              color: `${black}`,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {navs.navMain}
          </a>
        ) : (
          <LinkText
            key={index}
            to={navs.handle}
            size="16px"
            lineheight="24px"
            color={black}
          >
            {navs.navMain}
          </LinkText>
        )
      )}
    </Card>
  );
};

export default NavigationSection;
