import { Card, Image, InnerSection, MainSection } from "../../../ui/styles";
import LeftDiv from "./LeftDiv";

const InstantPayment = () => {
  return (
    <MainSection
      width="100%"
      mtop="100px"
      smmargin="70px 0 0"
      bg="rgba(39, 59, 74, 0.02)"
    >
      <InnerSection>
        <Card
          flex
          smflexdirection="column-reverse"
          justifycontent="space-between"
          alignitems="center"
          gap="50px"
          padding="100px"
          smpadding="50px 0 0"
        >
          <Image
            src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680689175/Fountainpay-V2_Assets/Group_rnnzxg.svg"
            alt="fountainpay"
            mddisplay="none"
            smdisplay="none"
          />
          <LeftDiv />
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default InstantPayment;
