import {
  AboutPage,
  AgencyBankingPage,
  CareerPage,
  CareerSinglePage,
  ComingSoonPage,
  CookiePolicyPage,
  FAQPage,
  GatewayPage,
  HomePage,
  PaymentSecurityPage,
  PricingPage,
  PrivacyPolicyPage,
  NeedHelpPage,
} from "./pages";

export const routes = [
  {
    title: "Home Page",
    subRoutes: [],
    component: <HomePage />,
    route: "/",
  },
  {
    title: "About Page",
    subRoutes: [],
    component: <AboutPage />,
    route: "/about",
  },
  {
    title: "Gateway Page",
    subRoutes: [],
    component: <GatewayPage />,
    route: "/gateway",
  },
  {
    title: "Need help?",
    subRoutes: [],
    component: <NeedHelpPage />,
    route: "/need-help",
  },
  {
    title: "Career",
    subRoutes: [],
    component: <CareerPage />,
    route: "/career",
  },
  {
    title: "Vacancy application",
    subRoutes: [],
    component: <CareerSinglePage />,
    route: "/application",
  },
  {
    title: "Careers",
    subRoutes: [],
    component: <CareerPage />,
    route: "/careers",
  },
  {
    title: "FAQ Page",
    subRoutes: [],
    component: <FAQPage />,
    route: "/faq",
  },
  {
    title: "AgencyBanking Page",
    subRoutes: [],
    component: <AgencyBankingPage />,
    route: "/agency-banking",
  },
  {
    title: "Pricing Page",
    subRoutes: [],
    component: <PricingPage />,
    route: "/pricing",
  },
  {
    title: "PrivacyPolicy Page",
    subRoutes: [],
    component: <PrivacyPolicyPage />,
    route: "/privacy",
  },
  {
    title: "CookiePolicy Page",
    subRoutes: [],
    component: <CookiePolicyPage />,
    route: "/cookie",
  },
  {
    title: "PaymentSecurity Page",
    subRoutes: [],
    component: <PaymentSecurityPage />,
    route: "/payment-security",
  },
  // {
  //   title: "Career Page",
  //   subRoutes: [],
  //   component: <CareerPage />,
  //   route: "/careers",
  // },
  // {
  //   title: "Career Single Page",
  //   subRoutes: [],
  //   component: <CareerSinglePage />,
  //   route: "/careers/:id",
  // },
  {
    title: "Coming Soon Page",
    subRoutes: [],
    component: <ComingSoonPage />,
    route: "/coming-soon",
  },
];
