import { coreValues } from "../../../../data";
import ProcessCard from "../../../ui/processCard";
import { Card, InnerSection, MainSection, white } from "../../../ui/styles";
import Title from "../../../ui/title";



const CoreValue = () => {
  return (
    <MainSection width="100%" mtop="100px" smmargin="70px 0 0">
      <InnerSection>
        <Card
          flex
          flexdirection="column"
          gap="50px"
          smgap="30px"
          alignitems="center"
        >
          <Title title="Our" span="Core Values" center />
          <Card
            display="grid"
            gridcolumn="repeat(3, 1fr)"
            gap="32px"
            mdgridcolumn="1fr 1fr"
            smgridcolumn="1fr"
          >
            {coreValues.map((value, index) => (
              <ProcessCard
                bg={white}
                key={index}
                title={value.title}
                text={value.text}
                icon={value.icon}
              />
            ))}
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default CoreValue;
