import React from "react";
import ListType from "../../../ui/listType";
import MainText from "../../../ui/mainText";
import { black, Card, InnerSection, MainSection } from "../../../ui/styles";
import Title from "../../../ui/title";

const settlementDetails = [
  {
    point:
      "If the merchant pays N2,000, we will settle for N1972 (1.4% of the total).",
    price: "NGN 28 charges per Settlement",
  },
  {
    point:
      "If the payee pays N2028, we will settle for N2000 (1.4% of the total).",
    price: "NGN 28 charges per Settlement",
  },
  {
    point:
      "If the merchant pays N3,001, we will settle for N2908.986 (1.4% + 50 of the total).",
    price: "NGN 92 charges per Settlement",
  },
  {
    point:
      "If the payee pays N3,092, we will settle for N3,000 (1.4% + 50 of the total).",
    price: "NGN 92 charges per Settlement",
  },
];

const Settlement = () => {
  return (
    <MainSection width="100%" mtop="100px" smmargin="70px 0 0">
      <InnerSection>
        <Card
          flex
          flexdirection="column"
          gap="50px"
          smgap="30px"
          padding="0 100px"
          mdpadding="0"
        >
          <Title title="Settlement" />
          <Card flex flexdirection="column">
            {settlementDetails.map((settlement, index) => (
              <Card
                key={index}
                flex
                justifycontent="space-between"
                alignitems="center"
                mdalignitems="unset"
                mdflexdirection="column"
                padding="20px 15px"
                btop="0.5px solid rgba(215, 215, 215, 1)"
                bbottom="0.5px solid rgba(215, 215, 215, 1)"
              >
                <ListType text={settlement.point} noIcon />
                <MainText text={settlement.price} heavy color={black} />
              </Card>
            ))}
            <Card margin="30px 0 0">
              <ListType
                invertStyle
                text="By default, the payee is responsible for transaction fees. This feature can be changed on your dashboard at any time."
              />
            </Card>
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default Settlement;
