import BackgroundGradient from "../components/ui/backgroundGradient";
import Layout from "../components/ui/layout";
import GetStarted from "../components/ui/layout/footer/GetStarted";
import OtherPagesGradient from "../components/ui/otherPagesGradient";
import { Card } from "../components/ui/styles";
import AgencyBanking from "../components/websitePageSections/agencyBanking/agencyBanking";
import AgencyBankingHeroSection from "../components/websitePageSections/agencyBanking/agencyBankingHeroSection";
import BankingFeatures from "../components/websitePageSections/agencyBanking/bankingFeatures";
import InstantPayment from "../components/websitePageSections/agencyBanking/instantPayment";

const AgencyBankingPage = () => {
  return (
    <Layout>
      <Card overflow="hidden" smmargin="-40px 0 0">
        <OtherPagesGradient />
        <AgencyBankingHeroSection />
        <InstantPayment />
        <BankingFeatures />
        <AgencyBanking />
        <GetStarted />
      </Card>
    </Layout>
  );
};

export default AgencyBankingPage;
