import { testimonialDetails } from "../../../../../data";
import MainText from "../../../../ui/mainText";
import { black, Card, Image, } from "../../../../ui/styles";
import { ITestimonialProps } from "./TestimonialBody";

const RightDiv = (props: ITestimonialProps) => {
  const { changingIndex } = props;
  return (
    <>
      {testimonialDetails.map(
        (testimonial, index) =>
          changingIndex === index && (
            <Card
              bg="#FBFBFB"
              radius="10px"
              height="472px"
              mdheight="unset"
              flex
              flexdirection="column"
              gap="90px"
              smgap="32px"
              padding="55px"
              smpadding="24px"
              key={index}
            >
              <Image
                src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680602381/Fountainpay-V2_Assets/Vector_lcefmz.png"
                alt="testimonial"
                width="37px"
              />
              <MainText
                text={testimonial.testimony}
                color={black}
                size="20px"
                width="683px"
              />
            </Card>
          )
      )}
    </>
  );
};

export default RightDiv;
