import DetailedListType from "../../../ui/detailedListType";
import { black, Card, Image, Text, TitleText } from "../../../ui/styles";

const processCheckoutDetails = [
  {
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680616698/Fountainpay-V2_Assets/Group_182_nrtyk5.svg",
    title: "Card Pay",
    text: "Pay seamlessly for a transaction with your debit card",
  },
  {
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680616698/Fountainpay-V2_Assets/Group_209_bjrymj.png",
    title: "Direct Debit",
    text: "Pay directly from your bank without logging in.",
  },
  {
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680616698/Fountainpay-V2_Assets/Group_183_gcc3y4.png",
    title: "QR Code",
    text: "QR code allows you to scan and pay for a product or service from your bank app.",
  },
];

const RightDiv = () => {
  return (
    <Card flexratio="1" flex flexdirection="column" gap="20px">
      {processCheckoutDetails.map((detail, index) => (
        <DetailedListType
          width="324px"
          title={detail.title}
          icon={detail.icon}
          text={detail.text}
          key={index}
        />
      ))}
    </Card>
  );
};

export default RightDiv;
