import { testimonialDetails } from "../../../../../data";
import MainText from "../../../../ui/mainText";
import { Card, deepBlue, Image, TitleText, white } from "../../../../ui/styles";
import { ITestimonialProps } from "./TestimonialBody";

const LeftDiv = (props: ITestimonialProps) => {
  const { changingIndex } = props;
  return (
    <>
      {testimonialDetails.map(
        (testimonial, index) =>
          changingIndex === index && (
            <Card flex flexdirection="column" gap="24px" key={index}>
              <Card
                width="286px"
                bg="rgba(248, 110, 127, 0.09)"
                padding="20px 0 0"
                smwidth="100%"
                flex
                justifycontent="center"
                radius="10px"
              >
                <Image
                  src={testimonial.image}
                  alt={testimonial.name}
                  smwidth="100%"
                />
              </Card>
              <Card
                bg={deepBlue}
                padding="28px 20px"
                radius="10px"
                flex
                gap="20px"
                flexdirection="column"
              >
                <TitleText
                  size="20px"
                  lineheight="27.2px"
                  weight="600"
                  color={white}
                >
                  {testimonial.name}
                </TitleText>
                <MainText text={testimonial.position} color={white} />
              </Card>
            </Card>
          )
      )}
    </>
  );
};

export default LeftDiv;
