import PageIntro from "../../../ui/pageIntro";
import {
  black,
  Card,
  Image,
  InnerSection,
  MainSection,
  primaryRed,
  TitleText,
} from "../../../ui/styles";
import PricingCards from "./PricingCards";

const PricingHeroSection = () => {
  return (
    <>
      <MainSection
        width="100%"
        height="700px"
        mdheight="unset"
        padding="100px 0 0"
        mdpadding="70px 0 0"
        data-aos="fade-up"
        position="relative"
        index="1"
      >
        <Card position="absolute" top="0" left="50%" transform="translateX(-50%)" index="-12" mddisplay="none">
          <Image src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680788666/Fountainpay-V2_Assets/Group_4211_ahxnou.svg" />
        </Card>
        <Card position="absolute" top="0" left="50%" transform="translateX(-50%)" index="-11" mddisplay="none">
          <Image src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680789375/Fountainpay-V2_Assets/Rectangle_4175_en0dsz.svg" />
        </Card>
        <InnerSection>
          <Card
            flex
            gap="25px"
            flexdirection="column"
            alignitems="center"
            mdflexdirection="column"
          >
            <PageIntro
              text="Pricing For Nigeria"
              icon="https://res.cloudinary.com/dyozdezpg/image/upload/v1680787543/Fountainpay-V2_Assets/Nigeria_NG_ngyqjs.svg"
            />
            <TitleText
              size="54px"
              lineheight="73.44px"
              mdsize="50px"
              mdlineheight="65px"
              smsize="30px"
              smlineheight="40px"
              weight="600"
              color={black}
              center
            >
              Fair pricing for{" "}
              <span style={{ color: `${primaryRed}` }}>every business</span>
            </TitleText>
            <PricingCards />
          </Card>
        </InnerSection>
      </MainSection>
    </>
  );
};

export default PricingHeroSection;
