import React from 'react'
import Layout from '../components/ui/layout'
import GetStarted from '../components/ui/layout/footer/GetStarted'
import OtherPagesGradient from '../components/ui/otherPagesGradient'
import { Card } from '../components/ui/styles'
import CareerHeroSection from '../components/websitePageSections/career/careerHeroSection'
import OpenRoles from '../components/websitePageSections/career/openRoles'

const CareerPage = () => {
  return (
    <Layout>
        <Card overflow="hidden" smmargin="-40px 0 0">
        <OtherPagesGradient />
        <CareerHeroSection />
        <OpenRoles />
        <GetStarted />
      </Card>
    </Layout>
  )
}

export default CareerPage