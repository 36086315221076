import ButtonStyle from "../../../ui/button";
import MainText from "../../../ui/mainText";
import PageIntro from "../../../ui/pageIntro";
import {
  black,
  Card,
  InnerSection,
  LinkText,
  MainSection,
  primaryRed,
  TitleText,
} from "../../../ui/styles";
import HeroImage from "./heroImage/HeroImage";

const AgencyBankingHeroSection = () => {
  return (
    <MainSection
      width="100%"
      padding="100px 0 0"
      mdpadding="70px 0 0"
      data-aos="fade-up"
    >
      <InnerSection>
        <Card
          flex
          gap="25px"
          flexdirection="column"
          alignitems="center"
          mdflexdirection="column"
        >
          <PageIntro text="Agency Banking" />
          <TitleText
            size="54px"
            lineheight="73.44px"
            width="900px"
            mdwidth="100%"
            mdsize="50px"
            mdlineheight="65px"
            smsize="30px"
            smlineheight="40px"
            weight="600"
            color={black}
            center
          >
            Bringing bank to your{" "}
            <span style={{ color: `${primaryRed}` }}>doorstep</span>
          </TitleText>
          <Card margin="-13px 0 0">
            <MainText
              text="Make withdrawals, pay bills and deposit without leaving your neighbourhood."
              center
            />
          </Card>
          <LinkText to="https://agency.fountainpay.ng/" target="_blank">
          <ButtonStyle text="Get Started" />
        </LinkText>
          {/* <a href="https://app.fountainpay.ng/" target="_blank">
            <ButtonStyle text="Get Started" />
          </a> */}

          <HeroImage />
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default AgencyBankingHeroSection;
