import { Card, Text } from "../../styles";

const Copyright = () => {
  return (
    <Card
      flex
      smflexdirection="column"
      alignitems="center"
      justifycontent="center"
      margin="78px 0 0"
      mdmargin="40px 0 0"
    >
      <Text size="16px" lineheight="21px" smsize="16px" center>
        Fountain Payment Systems and Solutions @2023
      </Text>
    </Card>
  );
};

export default Copyright;
