import { testimonialDetails } from "../../../../data";
import { Card, primaryRed } from "../../../ui/styles";
import { ITestimonialProps } from "./testimonialBody/TestimonialBody";

const TestimonialPagination = (props: ITestimonialProps) => {
  const { changingIndex, setChangingIndex } = props;
  return (
    <Card flex flexdirection="column" mdflexdirection="row" gap="16px" smmargin="20px 0 0">
      {testimonialDetails.map((testimonial, index) => (
        <Card
          width="10px"
          mdheight="10px"
          height={changingIndex === index ? "38px" : "10px"}
          mdwidth={changingIndex === index ? "38px" : "10px"}
          key={index}
          onClick={() => setChangingIndex(index)}
          radius= {changingIndex === index ? "10px" : "50%"}
          bg={changingIndex === index ? primaryRed : "#FAD6D6"}
          pointer
        />
      ))}
    </Card>
  );
};

export default TestimonialPagination;
