import styled, { keyframes } from "styled-components";
import { Card } from "../../../../ui/styles";

const animateOnSlide = keyframes`
  0%   { right: -650px; }
  100% { right: 0; }
`;
const animateOnSlide2 = keyframes`
  0%   { left: -400px; }
  100% { left: 0; }
`;

const AnimationImage = styled("img")`
  animation: ${animateOnSlide} 1s linear forwards;
  position: absolute;
  right: -650px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
const AnimationImage2 = styled("img")`
  animation: ${animateOnSlide2} 1s linear forwards;
  position: absolute;
  left: -100px;
  bottom: 0;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const HeroImage = () => {
  return (
    <Card flex gap="40px" width="100%">
      <Card
        flexratio="6"
        height="388px"
        bg="rgba(248, 110, 127, 0.09)"
        radius="10px"
        position="relative"
        overflow="hidden"
        smdisplay="none"
        mdwidth="100%"
      >
        <Card>
          <AnimationImage
            src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680689177/Fountainpay-V2_Assets/Group_164_3_cmluud.svg"
            alt="fountainpay"
          />
        </Card>
      </Card>
      <Card
        flexratio="4"
        height="388px"
        bg="rgba(248, 110, 127, 0.09)"
        radius="10px"
        position="relative"
        overflow="hidden"
        mddisplay="none"
        smdisplay="block"
        smwidth="100%"
        smheight="250px"
      >
        <AnimationImage2
          src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680689201/Fountainpay-V2_Assets/dash_ufmn05.svg"
          alt="fountainpay"
        />
      </Card>
    </Card>
  );
};

export default HeroImage;
