import { Card, InnerSection, MainSection } from "../../../ui/styles";
import LeftDiv from "./LeftDiv";
import RightDiv from "./RightDiv";

const Contact = () => {
  return (
    <MainSection
      id="contact"
      width="100%"
      mtop="100px"
      smmargin="70px 0 0"
      data-aos="fade-up"
    >
      <InnerSection>
        <Card flex gap="32px" alignitems="center">
          <LeftDiv />
          <RightDiv />
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default Contact;
