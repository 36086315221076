import axios, { AxiosError, AxiosResponse } from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

type PropT = {
  [x: string]: any;
};

interface CallResponse {
  status_code?: number;
  message: string;
  status: boolean;
  payload?: any;
  errors?: any;
  meta?: any;
}
export const queryStringToObject = (str: string, options = {}) =>
  queryString.parse(str, {
    arrayFormat: "bracket",
    ...options,
  });

export const objectToQueryString = (obj: {} | any[], options = {}) =>
  queryString.stringify(obj, {
    arrayFormat: "bracket",
    ...options,
  });

const defaults = {
  
  baseURL: process.env.REACT_APP_API_URL ?? "https://api-5916234be.fountainpay.ng", //"http://localhost:8000",//


  headers: () => {
    return {
      Accept: "application/json",
      "x-api-client": "api",
      "Content-Type": "application/json",
    };
  },
  error: {
    code: "INTERNAL_ERROR",
    message:
      "Something went wrong. Please check your internet connection or contact our support.",
    status: 503,
    data: {},
  },
};




const api = (method: string, url?: string, variables?: any, cache = true) => {
  
  return new Promise((resolve, reject) => {
    axios(<PropT>{
      url: `${defaults.baseURL}${url}`,
      method,
      headers: defaults.headers(),
      params: method === "get" ? variables : undefined,
      data:
        method !== "get"
          ? variables
          : undefined,
      paramsSerializer: objectToQueryString,
    }).then(
      (response: any) => {
        
        resolve(response.data);
      },
      (error: any) => {
        
        if (error?.code == "ERR_BAD_RESPONSE") {
          reject({
            code: 500,
            status: false,
            message: "System error. Kindly report via our feedback channel.",
          });
        }

        if (error.response) {
          if (error?.response?.status == "404") {
            reject({
              code: 404,
              status: false,
              message:
                "System resource not found or dropped. Kindly report via our feedback channel.",
            });
          }


          if (error?.response?.status == "400") {
            reject({
              code: 400,
              status: false,
              message: error?.response?.data?.message,
            });
          }

          if (
            ["INVALID_TOKEN", "token_not_valid"].includes(error.code) ||
            ([401].includes(error.response.status) &&
              error?.response?.data?.message !== "Invalid credentials")
          ) {

            if (
              !["/overview", "/authenticate"].includes(window.location.pathname)
            ) {
              toast.error(
                error?.response?.data?.message ?? "Request Timed Out"
              );
            }
          } else {
            reject(error?.response?.data);
          }
        } else {
          reject(defaults.error);
        }
      }
    );
  });
};

const optimisticUpdate = async (
  url: string,
  { updatedFields, currentFields, setLocalData }: PropT
) => {
  try {
    setLocalData(updatedFields);
    await api("put", url, updatedFields);
  } catch (error) {
    setLocalData(currentFields);
    toast.error("Invalid credentials");
  }
};

export default <PropT>{
  get: (...args: any) => api("get", ...args),
  post: (...args: any) => api("post", ...args),
  put: (...args: any) => api("put", ...args),
  patch: (...args: any) => api("patch", ...args),
  delete: (...args: any) => api("delete", ...args),
  optimisticUpdate,
};
