import { Card, InnerSection, MainSection } from "../../../ui/styles";
import Title from "../../../ui/title";
import FAQs from "./FAQs";

const FAQBody = () => {
  return (
    <MainSection width="100%" mtop="100px" smmargin="70px 0 0">
      <InnerSection>
        <Card width="100%">
          <Card
            flex
            flexdirection="column"
            gap="50px"
            smgap="30px"
            width="72%"
            smwidth="100%"
            margin="0 auto"
          >
            <Title
              title="Some frequently asked"
              span="questions"
              width="623px"
            />
            <FAQs />
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default FAQBody;
