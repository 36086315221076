import Layout from "../components/ui/layout";
import GetStarted from "../components/ui/layout/footer/GetStarted";
import { Card, MainSection } from "../components/ui/styles";

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Card overflow="hidden" smmargin="-40px 0 0">
        <div style={{ padding: '20px', lineHeight: '1.6', fontFamily: 'Arial, sans-serif', marginLeft: '10%', marginRight: '10%' }}>
          <h1>Privacy Policy</h1>

          <h2>Contents</h2>
          <ul>
            <li>WHAT INFORMATION DO WE COLLECT?</li>
            <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
            <li>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</li>
            <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
            <li>WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</li>
            <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
            <li>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
            <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
            <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
            <li>PERSONAL DATA BREACH NOTIFICATION</li>
            <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
            <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
            <li>PERSONAL DATA RETENTION PERIOD</li>
            <li>USE OF COOKIES</li>
          </ul>

          <h2>Introduction</h2>
          <p>
            This privacy notice for Fountain Payment Systems and Solution Limited (doing business as Fountainpay) describes how and why we might collect, store, use, and/or share (“process“) Data subject information when you use our services (“Services“).
          </p>
          
          
          <h2>WHAT INFORMATION DO WE COLLECT?</h2>
          <p>
            Personal Information/Contact details provided by you. The personal information (Any type of information, in any form, digital and non-digital, that can be associated with a specific individual) that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
          </p>
          <ul>
            <li>Full Name</li>
            <li>Email Address</li>
            <li>Contact Details</li>
            <li>Date of birth</li>
            <li>Identification information</li>
            <li>Phone number</li>
            <li>Information about your family and social circumstances (such as dependents, marital status, next of kin)</li>
            <li>Visual images and personal appearance (such as copies of passports)</li>
          </ul>

          <h2>HOW DO WE PROCESS YOUR INFORMATION?</h2>
          <p>
            We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
          </p>

          {/* Add more sections as needed based on the document */}

          <h2>USE OF COOKIES</h2>
          <p>
            Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology, to improve your experience while on our websites.
          </p>
        </div>
      </Card>
    </Layout>
  );
};

export default PrivacyPolicyPage;
