import MainText from "../../../ui/mainText";
import {
  black,
  Button,
  Card,
  deepBlue,
  InputLabel,
  TextField,
  white,
} from "../../../ui/styles";
import Title from "../../../ui/title";

const LeftDiv = () => {
  return (
    <Card
      bg={white}
      radius="10px"
      padding="75px 52px"
      smpadding="24px"
      flex
      gap="50px"
      flexdirection="column"
    >
      <Card>
        <Title title="Contact us" />
        <MainText text="Take that bold step today and enjoy this great business opportunity" />
      </Card>
      <form>
        <Card flex flexdirection="column" gap="46px">
          <Card>
            <InputLabel color={black} size="16px" weight="600" htmlFor="phone">
              PHONE NUMBER
            </InputLabel>
            <Card padding="4px 0" bbottom="0.5px solid #292929">
              <MainText text="+234 903 144 7585" />
            </Card>
          </Card>
          <Card>
            <InputLabel color={black} size="16px" weight="600" htmlFor="email">
              EMAIL ADDRESS
            </InputLabel>
            <Card padding="4px 0" bbottom="0.5px solid #292929">
              <MainText text="info@fountainpay.ng" />
            </Card>
          </Card>
          <Card>
            <InputLabel color={black} size="16px" weight="600" htmlFor="email2">
              EMAIL ADDRESS 2
            </InputLabel>
            <Card padding="4px 0" bbottom="0.5px solid #292929">
              <MainText text="support@fountainpay.ng" />
            </Card>
          </Card>
        </Card>
      </form>
    </Card>
  );
};

export default LeftDiv;
