import { footerContents } from "../../../../../data";
import {
  black,
  Card,
  InnerSection,
  LinkText,
  MainSection,
  TitleText,
  white,
} from "../../../styles";
import Copyright from "../Copyright";
import AboutFountainpay from "./AboutFountainpay";

const MainFooter = () => {
  return (
    <>
      <MainSection width="100%" bg="#FBFBFB" padding="50px 0">
        <InnerSection>
          <Card flex gap="80px" mddisplay="grid" mdgridcolumn="1fr 1fr" smgap="60px" smgridcolumn="1fr">
            <AboutFountainpay />
            {footerContents.map((item) => (
              <Card
                key={item.id}
                smwidth="unset"
                flex
                flexdirection="column"
                gap="18px"
              >
                <TitleText
                  color={black}
                  weight="700"
                  size="20px"
                  lineheight="38px"
                  smsize="16px"
                  smlineheight="20px"
                  heavy
                >
                  {item.heading}
                </TitleText>
                <Card flex flexdirection="column" gap="18px">
                  {item.details.map((subitem) =>
                    subitem.link === true ? (
                      <a
                        href={subitem.path}
                        target="_blank"
                        key={subitem.id}
                        style={{
                          width: "fit-content",
                          color: `${white}`,
                          fontWeight: "400",
                          fontSize: "16px",
                          textDecoration: "none",
                          lineHeight: "19px",
                        }}
                      >
                        {subitem.handle}
                      </a>
                    ) : (
                      <LinkText
                        to={subitem.path}
                        key={subitem.id}
                        color={black}
                        weight="400"
                        size="16px"
                        lineheight="19px"
                        smsize="16px"
                      >
                        {subitem.handle}
                      </LinkText>
                    )
                  )}
                </Card>
              </Card>
            ))}
          </Card>
          <Copyright />
        </InnerSection>
      </MainSection>
    </>
  );
};

export default MainFooter;
