import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  black,
  Card,
  InnerSection,
  MainSection,
  primaryRed,
  Text,
  TitleText,
  white,
} from "../../../ui/styles";
import Title from "../../../ui/title";

const OpenRoles = () => {
  const url = "https://abulesoro-career.cyclic.app/api/v1/job";
  const [isLoading, setIsLoading] = useState(false);
  const [careerDetails, setCareerDetails] = useState([
    {
      role: "",
      description: "",
      handle: "",
      tags: [],
    },
  ]);
  const [isHover, setIsHover] = useState(careerDetails.length + 1);

  const handleHover = (index: any) => {
    if (index === isHover) {
      setIsHover(careerDetails.length + 1);
    } else {
      setIsHover(index);
    }
  };

  const getRoles = async () => {
    setIsLoading(true);
    await axios({
      method: "GET",
      url,
    })
      .then((resp) => {
        setIsLoading(false);
        const formattedList = resp.data.map((data: any) => {
          return {
            role: data.title,
            description: data.desc,
            handle: data.link,
            tags: data.tag,
          };
        });
        setCareerDetails(formattedList);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getRoles();
  }, []);
  return (
    <MainSection
      width="100%"
      padding="100px 0 0"
      mdpadding="70px 0 0"
      data-aos="fade-up"
    >
      <InnerSection>
        {isLoading ? (
          <Card height="100px" flex justifycontent="center" alignitems="center">
            Loading...
          </Card>
        ) : (
          <Card
            flex
            gap="25px"
            flexdirection="column"
            padding="0 75px"
            mdpadding="0"
          >
            <Title title="See" span="open roles" />
            <Card
            width="100%"
              mdwidth="100%"
              flex
              flexdirection="column"
              gap="32px"
              smgap="16px"
              margin="0 auto"
            >
              {careerDetails.map((career, index) => (
                <a
                  href={career.handle}
                  target="_blank"
                  style={{ textDecoration: "none", color: `${black}` }}
                >
                  <Card
                    key={index}
                    radius="10px"
                    padding="20px 24px"
                    bg={white}
                    flex
                    alignitems="center"
                    justifycontent="space-between"
                    shadow={
                      isHover === index
                        ? "0px 4px 50px rgba(0, 0, 0, 0.05);"
                        : "0px 2px 10px rgba(0, 0, 0, 0.05)"
                    }
                    pointer
                    onMouseEnter={() => handleHover(index)}
                    onMouseLeave={() => handleHover(index)}
                  >
                    <Card flex flexdirection="column" gap="10px">
                      <TitleText
                        size="20px"
                        smsize="18px"
                        lineheight="36px"
                        smlineheight="24px"
                        heavy
                      >
                        {career.role}
                      </TitleText>
                      <Text
                        size="14px"
                        smsize=""
                        lineheight="20px"
                        smlineheight=""
                      >
                        {career.description}
                      </Text>
                      <Card
                        flex
                        gap="12px"
                        margin="15px 0 0"
                        smmargin="5px 0 0"
                      >
                        {career.tags.map((tag, index) => (
                          <Card
                            key={index}
                            padding="10px 20px"
                            smpadding="5px 10px"
                            border={`1px solid ${black}`}
                            radius="50px"
                            flex
                            gap="12px"
                            mdgap="5px"
                            alignitems="center"
                          >
                            {/* <Image src={tag.icon} /> */}
                            <Text
                              size="16px"
                              smsize="14px"
                              lineheight="24px"
                              smlineheight="20px"
                            >
                              {tag}
                            </Text>
                          </Card>
                        ))}
                      </Card>
                    </Card>
                    {isHover === index && (
                      <Card smdisplay="none">
                        <TitleText
                          height="fit-content"
                          color={white}
                          padding="12px 62px"
                          bg={primaryRed}
                          heavy
                          size="16px"
                          smsize=""
                          lineheight="24px"
                          smlineheight=""
                          radius="10px"
                        >
                          Apply
                        </TitleText>
                      </Card>
                    )}
                  </Card>
                </a>
              ))}
            </Card>
          </Card>
        )}
      </InnerSection>
    </MainSection>
  );
};

export default OpenRoles;
