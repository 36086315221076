import BackgroundGradient from '../components/ui/backgroundGradient'
import Layout from '../components/ui/layout'
import GetStarted from '../components/ui/layout/footer/GetStarted'
import OtherPagesGradient from '../components/ui/otherPagesGradient'
import { Card } from '../components/ui/styles'
import BusinessChoice from '../components/websitePageSections/gateway/businessChoice'
import GatewayHeroSection from '../components/websitePageSections/gateway/gatewayHeroSection'
import PaymentProcessing from '../components/websitePageSections/gateway/paymentProcessing'
import ProcessCheckout from '../components/websitePageSections/gateway/processCheckout'
import SignUpWithFountainpay from '../components/websitePageSections/gateway/signUpWithFountainpay'

const GatewayPage = () => {
  return (
    <Layout>
        <Card overflow='hidden' smmargin="-40px 0 0">
        <OtherPagesGradient />
          <GatewayHeroSection />
          <BusinessChoice />
          <PaymentProcessing />
          <SignUpWithFountainpay />
          <ProcessCheckout />
          <GetStarted />
        </Card>
    </Layout>
  )
}

export default GatewayPage