import { coreProductDetails } from "../../../../../data";
import { Card, Image, LinkText, primaryRed, Text } from "../../../../ui/styles";
import LeftDiv from "./LeftDiv";
import RightDIv from "./RightDIv";

const CoreProductBody = (props: {
  clickedIndex: any;
  handleClicked: Function;
}) => {
  const { clickedIndex, handleClicked } = props;
  return (
    <Card
      flex
      justifycontent="space-between"
      mdflexdirection="column"
      mdalignitems="unset"
      mdgap="32px"
      alignitems="center"
    >
      <LeftDiv clickedIndex={clickedIndex} handleClicked={handleClicked} />
      <RightDIv clickedIndex={clickedIndex} />
      {coreProductDetails.map(
        (detail, index) =>
          clickedIndex === index && (
            <LinkText
              display="none"
              gap="10px"
              smalignitems="center"
              to={detail.path}
              smdisplay="flex"
              key={index}
            >
              <Text size="16px" lineheight="27.2px" color={primaryRed} heavy>
                Learn more
              </Text>
              <Image
                src="https://res.cloudinary.com/dyozdezpg/image/upload/v1680520780/Fountainpay-V2_Assets/Vector_fkjqff.png"
                height="12px"
                margin="0 0 0 5px"
              />
            </LinkText>
          )
      )}
    </Card>
  );
};

export default CoreProductBody;
