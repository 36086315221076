import React from "react";
import { coreProductDetails } from "../../../../../data";
import {
  black,
  Card,
  deepBlue,
  gray,
  Text,
  TitleText,
  white,
} from "../../../../ui/styles";

const LeftDiv = (props: { clickedIndex: any; handleClicked: Function; }) => {
  const { clickedIndex, handleClicked } = props;
  return (
    <Card flex flexdirection="column" mdflexdirection="row" smflexdirection="column" mdwidth="100%" width="390px" smwidth="100%" gap="32px">
      {coreProductDetails.map((detail, index) => (
        <Card
          flex
          flexdirection="column"
          gap="20px"
          radius="10px"
          padding="20px 15px"
          bg={clickedIndex === index ? deepBlue : white}
          shadow="0px 4px 50px rgba(0, 0, 0, 0.05)"
          pointer
          key={index}
          onClick={() => handleClicked(index)}
        >
          <TitleText
            size="24px"
            lineheight="32px"
            weight="600"
            smsize="20px"
            smlineheight="28px"
            color={clickedIndex === index ? white : black}
          >
            {detail.title}
          </TitleText>
          <Text
            size="16px"
            lineheight="27px"
            smsize="14px"
            smlineheight="22px"
            color={clickedIndex === index ? white : gray}
          >
            {detail.text}
          </Text>
        </Card>
      ))}
    </Card>
  );
};

export default LeftDiv;
