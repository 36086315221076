import React from 'react'
import ButtonStyle from '../../../ui/button'
import PageIntro from '../../../ui/pageIntro'
import { black, Card, InnerSection, MainSection, primaryRed, TitleText } from '../../../ui/styles'
import HeroImage from './heroImage/HeroImage'

const CareerHeroSection = () => {
  return (
    <MainSection
      width="100%"
      padding="100px 0 0"
      mdpadding="70px 0 0"
      data-aos="fade-up"
    >
      <InnerSection>
        <Card
          flex
          gap="25px"
          flexdirection="column"
          alignitems="center"
          mdflexdirection="column"
          overflow='hidden'
        >
          <PageIntro text="Careers" />
          <TitleText
            size="54px"
            lineheight="73.44px"
            width="900px"
            mdwidth="100%"
            mdsize="50px"
            mdlineheight="65px"
            smsize="30px"
            smlineheight="40px"
            weight="600"
            color={black}
            center
          >
            Join the coolest people on the {" "}
            <span style={{ color: `${primaryRed}` }}>block</span>
          </TitleText>
          <a href="/application" target="_blank">
            <ButtonStyle text="View open roles" />
          </a>

          <HeroImage />
        </Card>
      </InnerSection>
    </MainSection>
  )
}

export default CareerHeroSection