import Layout from "../components/ui/layout";
import GetStarted from "../components/ui/layout/footer/GetStarted";
import OtherPagesGradient from "../components/ui/otherPagesGradient";
import { Card } from "../components/ui/styles";
import FAQBody from "../components/websitePageSections/faq/faqBody";
import FAQHeroSection from "../components/websitePageSections/faq/faqHeroSection";

const FAQPage = () => {
  return (
    <Layout>
      <Card overflow="hidden" smmargin="-40px 0 0">
        <OtherPagesGradient />
        <FAQHeroSection />
        <FAQBody />
        <GetStarted />
      </Card>
    </Layout>
  );
};

export default FAQPage;
